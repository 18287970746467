import * as C from './styles';
import { TbError404 } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { BiChevronLeft } from 'react-icons/bi';

export const NotFound = () => {
    const navigation = useNavigate();

    const handleBack = () => {
        navigation(-1);
    }

    return(
        <C.Container>
            <C.BoxError>
                Erro: <div><TbError404 /></div>
            </C.BoxError>
            
            <C.AreaText>
                <h2>Sorry! Página não encontrada.</h2>
            </C.AreaText>

            <button onClick={handleBack}><BiChevronLeft size={22}/> Voltar</button>
        </C.Container>
    );
}