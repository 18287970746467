import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    display: flex;
    margin: auto;
    background-color: rgba(0,0,0, .6);
    transition: all .2s ease-in;
    opacity: 1;
    animation: show .2s ease-in;
    z-index: 1200;

    @keyframes show {
        0% {
            opacity: .8;
        }
        100% {
            opacity: 1;
        }
    }
`;

type PropsContent = {
    mxheight?: string;
}

export const Content = styled.div<PropsContent>`
    width: 70%;
    max-width: 700px;
    margin: 3% auto 0 auto;
    height:280px;
    background-color:#FFF;
    border-radius: 8px;
    z-index: 9000;
    transition: all .2s ease;
    animation: showModal .3s ease;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;


    @keyframes showModal {
        0% {
            opacity: .5;
            margin-top: -10px;
        }
        100% {
            opacity: 1;
            margin-top: 3%;
        }
    }

    @media screen and (max-width:760px) {
        width: 90%;   
        height: 100%;
    }
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size:1.2rem;
    padding: 15px 30px 15px 20px;
    border-bottom: 1px solid #ebebeb;
    h4 {
        font-weight: normal;
    }

    button{
        width: 24px;
        height: 24px;
        background: none;
        border: none;
    }

`;

export const BoxInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: calc(100% - 60px);
    padding: 2% 2% 5px 2%;
    overflow-y: auto;
    position: relative;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #ebebeb;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 20px;
        border: 3px solid #ccc;
    }

    @media screen and (max-width:1100px) {
        gap: 0;
    }
`;

type ImgProp = {
    src: any;
}

export const Img = styled.img<ImgProp>`
    width: 100%;
    max-height:320px;
    margin-bottom: 10px;
    border-radius: 8px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-image: url(${prop => prop.src});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
    }
`;

export const InfoProd = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    /* border: 1px solid #ebebeb; */
    padding: 0rem 0.5rem 0.5rem;
    border-radius: 5px;
    flex:1;

    h4 {
        font-weight: normal;
        font-size: 1.2rem;
        margin-bottom: 10px;
    }

    div {
        color: #444;
        margin-bottom: 16px;

    }
    
    p {
        font-size:1.3rem;
        margin: 15px 0 25px 0;
    }

    div.content-button{
        display: flex;
        gap: 16px;
    }
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 0px 0px;
    border-top: 1px solid #ebebeb;
    

    /* div {
        padding: 8px 15px;
        background-color: #f1f2f3;
        border: 1px solid #ccc;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background-color: #eeebeb;
        }
    } */
    .QtdSendCard{
        margin-right: 1rem;
        display: flex;
        align-items: center;

        button{
            height: 2rem;
            width: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background-color: transparent;
        }
        span{
            height: 2rem;
            width: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0.5rem;
            border: 1px solid #bbb;
            border-radius: 5px;
        }
    }
    

    & button.AddCart{
        display: flex;
        background-color: #16C521;
        border: none;
        border-radius: 5px;
        overflow: hidden;
        align-items: center;
        padding-left: 0.5rem;
        color:#fff;

        div{
            background-color: rgba(46,125,50,1);
            display: flex;
            align-items: center;
            height: 2rem;
            justify-content: center;
            margin-left: 0.5rem;

            span{
                margin: 0px 0.5rem;
            }
        }
    }
`;

export const BoxCart = styled.div`
    margin-left: auto;
    height: 5px;
`;