import * as C from './styles';
import LogoGetap from '../../assets/png/getap.png';
import { Link, NavLink } from 'react-router-dom';
import { BiPowerOff } from 'react-icons/bi';
import { useAuth } from '../../hooks/auth';

export const Header = () => {
    const {signOut, user} = useAuth();
    return (
        <C.Header>
            <C.Logo>
                <img src={LogoGetap} alt="Getap" />
            </C.Logo>
            <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><a href="https://getap.agr.br/quem-somos/">Quem somos</a></li>
                <li><a href="https://getap.agr.br/eventos/">Eventos</a></li>
                <li><a href="https://getap.agr.br/noticias/">Notícias</a></li>
                <li><a href="https://getap.agr.br/contato/">Contato</a></li>
            </ul>
            {!user ? <C.CtaSubscribe>
                <span>Participe do Getap</span>
                <button><Link to="/cadastro">INSCREVA-SE</Link></button>
            </C.CtaSubscribe> :
            <C.CtaLogout onClick={signOut}>
                <BiPowerOff size={16} style={{
                    marginRight: '8px'
                }}/>
                Sair
            </C.CtaLogout>}
        </C.Header>
    )
}