import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../components/Button/Button";
import { Input } from "../../components/input/Input";
import api from "../../services/api";
import * as C from "./styles";

export const UpdatePass = () => {
  const [email, setEmail] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassC, setNewPassC] = useState("");
  const navigate = useNavigate();

  const handleLogin = useCallback(async () => {
    if (email === "" || oldPass === "" || newPass === "" || newPassC === "") {
      toast.error("Preencha todos os campos!");
    }

    if (newPass !== newPassC) {
      toast.error("Senha/Confrimar senha são divergentes!");
    }

    api
      .post("/users/updatepass", {
        email,
        oldPass,
        newPass,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Senha alterada com sucesso!");
          navigate("/");
        }
      })
      .catch((e) => toast.error(`${e.response.data.message}`));
  }, [email, navigate, oldPass, newPass, newPassC]);

  return (
    <>
      {/* <Header /> */}
      <C.Container>
        <h2>
          Desafio nacional de máxima <br /> produtividade de milho
        </h2>
        <C.FormLogin>
          <C.ContentFormWrapper>
            <h3>Alterar senha!</h3>
            <Input
              label="E-mail cadastrado"
              plcholder="nome@dominio.com.br"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <Input
              type="password"
              label="Senha atual"
              plcholder="************"
              onChange={(e) => setOldPass(e.target.value)}
              value={oldPass}
            />
            <Input
              type="password"
              label="Nova senha"
              plcholder="************"
              onChange={(e) => setNewPass(e.target.value)}
              value={newPass}
            />
            <Input
              type="password"
              label="Confirmar nova senha"
              plcholder="************"
              onChange={(e) => setNewPassC(e.target.value)}
              value={newPassC}
            />

            <Button
              onClick={handleLogin}
              title="ALTERAR SENHA"
              color="#27AE60"
            />

            <Button
              onClick={() => navigate("/cadastro")}
              title="NÃO POSSUI CONTA? CRIE UMA AGORA!"
              color="#2F80ED"
            />
          </C.ContentFormWrapper>
        </C.FormLogin>
      </C.Container>
    </>
  );
};
