import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiChevronLeft, BiChevronRight, BiPlusCircle } from 'react-icons/bi';

import 'devextreme/dist/css/dx.light.css';
import { DataGrid, NumberBox, SelectBox, TextBox } from 'devextreme-react';
import {
  DataEstadioFenologico,
  HelpAdubacaoCobertura,
  HelpAdubacaoFisioVegetal,
  HelpAdubacaoFoliar,
  HelpAdubacaoPreSeme,
  HelpAdubacaoSeme,
  UnidadeMedidaFormulaAdubo,
  outros_nutrientes,
} from '../../data/data';
import { useCallback, useEffect, useMemo, useState } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '../../components/Tooltip';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Column } from 'devextreme-react/data-grid';
import { Modal } from '../../components/Modal';
import { useAppStore } from '../../store';
import { IListItem } from '../../store/types';
import MultiSelectDropdownv2 from '../../components/MultiSelectDropdownv2';
import fertilizantesData from '../../data/fertilizantes.json';
import AutoCompleteCustom, {
  IFertilizante,
} from '../../components/AutoCompleteCustom';

export const FormStep10 = () => {
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const setSubscription = useAppStore((store) => store.setSubscription);
  const loadListNutriCultive = useAppStore(
    (store) => store.loadListNutriCultive
  );
  const listNutriCultive = useAppStore((store) => store.listNutriCultive);
  const setStep = useAppStore((store) => store.setStep);
  const navigate = useNavigate();

  const [nome, setNome] = useState('');
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [isPopupEditVisible, setPopupEditVisibility] = useState(false);
  const [isModalVisibel, setIsModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');

  const [idToUpdate, setIdToUpdate] = useState('');
  const [formula, setFormula] = useState('');
  const [dose, setDose] = useState('');
  const [dose_un_medida, setDoseUnMedida] = useState('');
  const [nomeComercial, setNomeComercial] = useState('');
  const [fabricante, setFabricante] = useState('');
  const [estadioFenologico, setEstadioFenologico] = useState('');
  const [outros, setOutros] = useState('');

  const [qtd_pre_seme, setQtdPreSeme] = useState(
    subscription.nutrition?.qtd_pre_seme
  );
  const [qtd_seme, setQtdSeme] = useState(subscription.nutrition?.qtd_seme);
  const [qtd_cobertura, setQtdCobertura] = useState(
    subscription.nutrition?.qtd_cobertura
  );
  const [qtd_foliar, setQtdFoliar] = useState(
    subscription.nutrition?.qtd_foliar
  );
  const [qtd_espec_fisio, setQtdEspecFisio] = useState(
    subscription.nutrition?.qtd_espec_fisio
  );

  const subscription_id = localStorage.getItem('@GetapAPP:subscription_id');
  const [nitrogenio, setNitrogenio] = useState('');
  const [fosforo, setFosforo] = useState('');
  const [potassio, setPotassio] = useState('');
  const [outrosNutrientesSelected, setOutrosNutrientesSelected] = useState([
    '',
  ]);

  useEffect(() => {
    if (
      subscription_id !== null &&
      Number(subscription_id) !== subscription.id
    ) {
      loadSubscription(Number(subscription_id));
      loadListNutriCultive(Number(subscription_id));
    }

    setQtdPreSeme(subscription.nutrition?.qtd_pre_seme);
    setQtdSeme(subscription.nutrition?.qtd_seme);
    setQtdCobertura(subscription.nutrition?.qtd_cobertura);
    setQtdFoliar(subscription.nutrition?.qtd_foliar);
    setQtdEspecFisio(subscription.nutrition?.qtd_espec_fisio);
  }, [loadSubscription, subscription, subscription_id, loadListNutriCultive]);

  const nutri = useMemo(() => {
    const PRESEMEADURA = listNutriCultive.reduce(
      (acc: IListItem[], item: IListItem) => {
        return item.nome === 'PRESEMEADURA' ? (acc = [...acc, item]) : acc;
      },
      [] as IListItem[]
    );

    const SEMEADURA = listNutriCultive.reduce(
      (acc: IListItem[], item: IListItem) => {
        return item.nome === 'SEMEADURA' ? (acc = [...acc, item]) : acc;
      },
      [] as IListItem[]
    );

    const COBERTURA = listNutriCultive.reduce(
      (acc: IListItem[], item: IListItem) => {
        return item.nome === 'COBERTURA' ? (acc = [...acc, item]) : acc;
      },
      [] as IListItem[]
    );

    const FOLIAR = listNutriCultive.reduce(
      (acc: IListItem[], item: IListItem) => {
        return item.nome === 'FOLIAR' ? (acc = [...acc, item]) : acc;
      },
      [] as IListItem[]
    );

    const ESPECIALIDADES = listNutriCultive.reduce(
      (acc: IListItem[], item: IListItem) => {
        return item.nome === 'ESPECIALIDADES' ? (acc = [...acc, item]) : acc;
      },
      [] as IListItem[]
    );

    return {
      PRESEMEADURA,
      SEMEADURA,
      COBERTURA,
      FOLIAR,
      ESPECIALIDADES,
    };
  }, [listNutriCultive]);

  const togglePopup = useCallback(
    (title: string, name: string) => {
      setIsModalVisible(true);
      setPopupVisibility(!isPopupVisible);
      // setTitulo(title);
      setNome(name);

      setIdToUpdate('');
      setFormula('');
      setDose('');
      setDoseUnMedida('Kg/ha');
      setNomeComercial('');
      setFabricante('');

      setEstadioFenologico('');
      setOutros('');
      setNitrogenio('');
      setFosforo('');
      setPotassio('');
      setOutrosNutrientesSelected([]);
    },
    [isPopupVisible, setPopupVisibility]
  );

  const togglePopupEdit = useCallback(
    (data: any) => {
      setIsModalEditVisible(true);
      setPopupEditVisibility(!isPopupEditVisible);

      setIdToUpdate(data.data.id);
      setFormula(data.data.formula);
      setDose(data.data.dose);
      setDoseUnMedida(data.data.dose_un_medida || 'Kg/ha');
      setNomeComercial(data.data.nome_comercial);
      setFabricante(data.data.fabricante);
      setNome(data.data.nome);

      setEstadioFenologico(data.data.estadio_veget_feno);
      console.log(`data.data = ${data.data.outros_nutrientes}`);
      setOutros(data.data.nome_outros);
      setNitrogenio(nitrogenio);
      setFosforo(fosforo);
      setPotassio(potassio);

      // setOutrosNutrientes(data.data.outros_nutrientes);
    },
    [isPopupEditVisible, fosforo, potassio, nitrogenio]
  );

  const loadData = useCallback(async () => {
    await loadListNutriCultive(subscription.id);
  }, [subscription, loadListNutriCultive]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const cleanData = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
    setNome('');
    setIdToUpdate('');
    setNome('');
    setFormula('');
    setDose('');
    setNomeComercial('');
    setFabricante('');
    setEstadioFenologico('');
    setOutros('');
  }, [isPopupVisible]);

  const handleSubmitInfo = useCallback(async () => {
    if (
      nitrogenio === '' ||
      fosforo === '' ||
      potassio === '' ||
      dose === '' ||
      fabricante === '' ||
      nomeComercial === '' ||
      estadioFenologico === ''
    ) {
      return toast.error('Preencha todos os campos obrigratorios!');
    }
    if (idToUpdate !== '') {
      api
        .put('/subscription/list', {
          list_id: idToUpdate,
          nome,
          formula,
          dose,
          dose_un_medida,
          nome_comercial: nomeComercial,
          fabricante,
          estadio_veget_feno: estadioFenologico,
          nome_outros: outros,
        })
        .then((res) => {
          if (res.status === 200) {
            loadData();

            setPopupEditVisibility(false);
            setPopupVisibility(false);
            setIsModalVisible(false);
            setIsModalEditVisible(false);
            toast.success('Registro atualizado com sucesso!');
          }
        })
        .catch((e) => toast.error(`${e.response.data.message}`))
        .finally(() => {
          cleanData();
        });
    } else {
      api
        .post('/subscription/list_cultive', {
          subscription_id: subscription.id,
          nome,
          formula,
          dose,
          dose_un_medida,
          nome_comercial: nomeComercial,
          fabricante,
          estadio_veget_feno: estadioFenologico,
          nome_outros: outros,
          nitrogenio,
          fosforo,
          potassio,
          outros_nutrientes: outrosNutrientesSelected,
        })
        .then((res) => {
          if (res.status === 201) {
            loadData();
            setPopupEditVisibility(false);
            setPopupVisibility(false);
            setIsModalVisible(false);
            toast.success('Registro salvo com sucesso!');
          }
        })
        .catch((e) => toast.error(`${e.response.data.message}`))
        .finally(() => {
          cleanData();
        });
    }
  }, [
    subscription,
    setPopupVisibility,
    loadData,
    nome,
    formula,
    dose,
    dose_un_medida,
    nomeComercial,
    fabricante,
    idToUpdate,
    estadioFenologico,
    outros,
    nitrogenio,
    fosforo,
    potassio,
    cleanData,
    outrosNutrientesSelected,
  ]);

  const [screen, setScreen] = useState(0);

  const handleSubmitFinishStep = useCallback(() => {
    if (
      qtd_pre_seme === undefined ||
      qtd_seme === undefined ||
      qtd_foliar === undefined ||
      qtd_cobertura === undefined ||
      qtd_espec_fisio === undefined
    ) {
      return toast.error('Número de aplicações é obrigatório!');
    }

    if (qtd_pre_seme > 0 && nutri.PRESEMEADURA.length === 0) {
      return toast.error('Adicione pelo menos uma aplicação Pre-semeadura!');
    }
    if (qtd_seme > 0 && nutri.SEMEADURA.length === 0) {
      return toast.error('Adicione pelo menos uma aplicação Semeadura!');
    }
    if (qtd_cobertura > 0 && nutri.COBERTURA.length === 0) {
      return toast.error('Adicione pelo menos uma aplicação Cobertura!');
    }
    if (qtd_foliar > 0 && nutri.FOLIAR.length === 0) {
      return toast.error('Adicione pelo menos uma aplicação Foliar!');
    }
    if (
      qtd_espec_fisio > 0 &&
      nutri.ESPECIALIDADES.length !== qtd_espec_fisio
    ) {
      return toast.error(
        `Adicione todas a(s) ${qtd_espec_fisio} ESPECIALIDADES que você declarou !`
      );
    }

    api
      .post(`/subscription/nutrition`, {
        subscription_id: subscription.id,
        step: 10,
        qtd_pre_seme,
        qtd_seme,
        qtd_foliar,
        qtd_cobertura,
        qtd_espec_fisio,
      })
      .then((res) => {
        setSubscription(res.data);
        setStep(10);
        toast.success('Dados salvos com sucesso!');
        navigate('/passo11');
      })
      .catch((err) => {
        return toast.error(err.response.data.message);
      });
  }, [
    navigate,
    setStep,
    subscription,
    setSubscription,
    qtd_pre_seme,
    qtd_seme,
    qtd_foliar,
    qtd_cobertura,
    qtd_espec_fisio,
    nutri,
  ]);

  useEffect(() => {
    setScreen(window.screen.width);
  }, [screen]);

  const openModalDelete = (id: string) => {
    setIdToDelete(id);
    setIsModalDeleteVisible(true);
  };

  const handleDeleteRegister = () => {
    api
      .delete(`/subscription/list/${idToDelete}`)
      .then((res) => {
        loadData();
        toast.success('Registro excluído com sucesso');
        setIsModalDeleteVisible(false);
      })
      .catch((e) => toast.error(`${e.response.data.message}`))
      .finally(() => {
        setPopupVisibility(!isPopupVisible);
        setNome('');
        loadData();
      });
  };

  const handleButtonsActions = (data: any) => {
    return (
      <C.BoxUpdateDelete>
        <FaEdit
          cursor={'pointer'}
          onClick={() => togglePopupEdit(data)}
          color={'#2F80ED'}
        />
        <FaTrash
          cursor={'pointer'}
          onClick={() => openModalDelete(data.data.id)}
          color={'#e73838'}
        />
      </C.BoxUpdateDelete>
    );
  };

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Informações da área inscrita: Nutrição de plantas' />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>
        <br />
        <C.ContentRightForm>
          <C.BoxFields>
            <C.ContentRightFormBoxLeft>
              <C.BoxButton>
                <C.Label>
                  Adubação pré semeadura{' '}
                  <span style={{ color: '#2F80ED' }}>
                    <Tooltip
                      info={HelpAdubacaoPreSeme}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_pre_seme !== undefined && qtd_pre_seme > 0 && (
                  <C.BtnAdd
                    onClick={() =>
                      togglePopup('Adubação de pré-semeadura', 'PRESEMEADURA')
                    }
                    style={{ marginRight: '20px' }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={'#333'} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_pre_seme}
                  onValueChange={setQtdPreSeme}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Número de aplicações (*)'
                  width='200px'
                  height={50}
                  style={{ marginLeft: '20px' }}
                />
              </C.BoxButton>
              {isModalVisibel && (
                <Modal
                  titleHeader='Preencha os campos abaixo'
                  onClose={() => setIsModalVisible(false)}
                  mxheight='650px'

                  // onHiding={() =>togglePopup('Adubação pré-semeadura')}
                >
                  <C.GroupInputs>
                    <C.BoxInput>
                      <NumberBox
                        value={Number(nitrogenio)}
                        onValueChange={setNitrogenio}
                        stylingMode='underlined'
                        labelMode='static'
                        label='N (nitrogênio) %(*)'
                        max={99}
                        width='100%'
                        height={50}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <NumberBox
                        value={Number(fosforo)}
                        onValueChange={setFosforo}
                        stylingMode='underlined'
                        labelMode='static'
                        label='P2OS (fósforo) %(*)'
                        width='100%'
                        height={50}
                        max={99}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <NumberBox
                        value={Number(potassio)}
                        onValueChange={setPotassio}
                        stylingMode='underlined'
                        labelMode='static'
                        label='K2O (potássio) %(*)'
                        width='100%'
                        height={50}
                        max={99}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      {/* <SelectBox
                        value={outrosNutrientes}
                        items={outros_nutrientes}
                        onValueChanged={(e) => setOutrosNutrientes(e.value)}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Outros nutrientes"
                        placeholder="Selecione"
                        width="100%"
                        height={50}
                      /> */}
                      Outros nutrientes(*)
                      {/* <MultiSelectDropdown options={outros_nutrientes} /> */}
                      <MultiSelectDropdownv2
                        options={outros_nutrientes}
                        selectedOptions={outrosNutrientesSelected}
                        setSelectedOptions={setOutrosNutrientesSelected}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <NumberBox
                        value={Number(dose)}
                        onValueChange={setDose}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Dose (*)'
                        width='100%'
                        height={50}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <SelectBox
                        value={dose_un_medida}
                        onValueChange={setDoseUnMedida}
                        items={UnidadeMedidaFormulaAdubo}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Unidade de medida'
                        width={150}
                        height={50}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <TextBox
                        value={nomeComercial}
                        onValueChange={setNomeComercial}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Nome Comercial (*)'
                        width='100%'
                        height={50}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      {/* <TextBox
                        value={fabricante}
                        onValueChange={setFabricante}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Fabricante"
                        width="100%"
                        height={50}
                        style={{ marginRight: "20px" }}
                      /> */}
                      <AutoCompleteCustom<IFertilizante>
                        itemsAvailable={fertilizantesData}
                        valueExpr='razao_social'
                        searchExpr='razao_social'
                        inputValue={fabricante}
                        label='Fabricante (*)'
                        onValueChanged={(e) => setFabricante(e)}
                        labelMode='static'
                        stylingMode='underlined'
                        height={50}
                        typeData='fertilizante'
                        searchMode='startswith'
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <SelectBox
                        value={estadioFenologico}
                        onValueChange={setEstadioFenologico}
                        items={DataEstadioFenologico}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Estádio Fenológico (*)'
                        width='100%'
                        height={50}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    {estadioFenologico === 'Outros' && (
                      <C.BoxInput>
                        <TextBox
                          value={outros}
                          onValueChange={setOutros}
                          stylingMode='underlined'
                          labelMode='static'
                          label='Digite o estádio fenológico'
                          width='100%'
                          height={50}
                          style={{ marginRight: '20px' }}
                        />
                      </C.BoxInput>
                    )}
                  </C.GroupInputs>
                  <br />
                  <C.BoxButtonSubmit>
                    <Button
                      onClick={handleSubmitInfo}
                      title='SALVAR'
                      color='#27AE60'
                      style={{ marginLeft: 'auto' }}
                      iconRight={<BiChevronRight fontSize={22} />}
                    />
                  </C.BoxButtonSubmit>
                </Modal>
              )}

              {isModalEditVisible && (
                <Modal
                  titleHeader='Preencha os campos abaixo'
                  onClose={() => setIsModalEditVisible(false)}
                  mxheight='650px'

                  // onHiding={() =>togglePopup('Adubação pré-semeadura')}
                >
                  <C.GroupInputs>
                    <C.BoxInput>
                      {/* <NumberBox
                    value={nitrogenio}
                    onValueChange={setNitrogenio}
                    stylingMode="underlined"
                    labelMode="static"
                    label="N (nitrogênio) %"
                        width="100%"
                        height={50}
                    style={{ marginLeft: "20px" }}
                  /> */}
                      <NumberBox
                        value={Number(nitrogenio)}
                        onValueChange={setNitrogenio}
                        stylingMode='underlined'
                        labelMode='static'
                        label='N (nitrogênio) %'
                        width='100%'
                        height={50}
                        max={99}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <NumberBox
                        value={Number(fosforo)}
                        onValueChange={setFosforo}
                        stylingMode='underlined'
                        labelMode='static'
                        label='P2OS (fósforo) %'
                        width='100%'
                        height={50}
                        max={99}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <NumberBox
                        value={Number(potassio)}
                        onValueChange={setPotassio}
                        stylingMode='underlined'
                        labelMode='static'
                        label='K2O (potássio) %'
                        width='100%'
                        max={99}
                        height={50}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      Outros nutrientes
                      {/* <SelectBox
                      value={outrosNutrientes}
                      items={outros_nutrientes}
                      onValueChanged={(e) => setOutrosNutrientes(e.value)}
                      stylingMode="underlined"
                      labelMode="static"
                      label="Outros nutrientes"
                      placeholder="Selecione"
                      width="100%"
                      height={50}
                    /> */}
                      <MultiSelectDropdownv2
                        options={outros_nutrientes}
                        selectedOptions={outrosNutrientesSelected}
                        setSelectedOptions={setOutrosNutrientesSelected}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <TextBox
                        value={dose}
                        onValueChange={setDose}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Dose (*)'
                        width='100%'
                        height={50}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <SelectBox
                        value={dose_un_medida}
                        onValueChange={setDoseUnMedida}
                        items={UnidadeMedidaFormulaAdubo}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Unidade de medida'
                        width={150}
                        height={50}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <TextBox
                        value={nomeComercial}
                        onValueChange={setNomeComercial}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Nome Comercial (*)'
                        width='100%'
                        height={50}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      {/* <TextBox
                        value={fabricante}
                        onValueChange={setFabricante}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Fabricante"
                        width="100%"
                        height={50}
                        style={{ marginRight: "20px" }}
                      /> */}
                      <AutoCompleteCustom<IFertilizante>
                        itemsAvailable={fertilizantesData}
                        valueExpr='razao_social'
                        searchExpr='razao_social'
                        inputValue={fabricante}
                        label='Fabricante (*)'
                        onValueChanged={(e) => setFabricante(e)}
                        labelMode='static'
                        stylingMode='underlined'
                        height={50}
                        typeData='fertilizante'
                        searchMode='startswith'
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <SelectBox
                        value={estadioFenologico}
                        onValueChange={setEstadioFenologico}
                        items={DataEstadioFenologico}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Estádio Fenológico (*)'
                        width='100%'
                        height={50}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    {estadioFenologico === 'Outros' && (
                      <C.BoxInput>
                        <TextBox
                          value={outros}
                          onValueChange={setOutros}
                          stylingMode='underlined'
                          labelMode='static'
                          label='Digite o estádio fenológico'
                          width='100%'
                          height={50}
                          style={{ marginRight: '20px' }}
                        />
                      </C.BoxInput>
                    )}
                  </C.GroupInputs>
                  <br />
                  <C.BoxButtonSubmit>
                    <Button
                      onClick={handleSubmitInfo}
                      title='ATUALIZAR'
                      color='#27AE60'
                      style={{ marginLeft: 'auto' }}
                      iconRight={<BiChevronRight fontSize={22} />}
                    />
                  </C.BoxButtonSubmit>
                </Modal>
              )}

              {isModalDeleteVisible && (
                <Modal
                  titleHeader='Excluier registro'
                  onClose={() => setIsModalDeleteVisible(false)}
                  mxheight='220px'
                >
                  <h2 style={{ margin: '0 auto', color: '#444' }}>
                    Deseja excluir este registro?
                  </h2>
                  <br />

                  <C.BoxButtonSubmit>
                    <Button
                      onClick={handleDeleteRegister}
                      title='Excluir'
                      color='#27AE60'
                      style={{ marginLeft: 'auto' }}
                    />
                  </C.BoxButtonSubmit>
                </Modal>
              )}

              {nutri?.PRESEMEADURA !== undefined &&
                nutri?.PRESEMEADURA.length > 0 && (
                  <DataGrid
                    dataSource={nutri?.PRESEMEADURA}
                    focusedRowEnabled={false}
                    hoverStateEnabled={true}
                    keyExpr={'id'}
                  >
                    <Column caption={'N'} dataField={'nitrogenio'} />
                    <Column caption={'P2OS'} dataField={'fosforo'} />
                    <Column caption={'K2O'} dataField={'potassio'} />
                    <Column
                      caption={'Estádio Fenológico'}
                      dataField={'estadio_veget_feno'}
                    />
                    <Column caption={'Dose (kg/ha)'} dataField={'dose'} />
                    <Column
                      caption={'Unid. Medida'}
                      dataField={'dose_un_medida'}
                    />
                    <Column caption={'Fabricante'} dataField={'fabricante'} />
                    <Column
                      caption={'#'}
                      alignment={'center'}
                      cellRender={handleButtonsActions}
                    />
                  </DataGrid>
                )}

              <C.Separator />

              <C.BoxButton>
                <C.Label>
                  Adubação de semeadura{' '}
                  <span style={{ color: '#2F80ED' }}>
                    <Tooltip
                      info={HelpAdubacaoSeme}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_seme !== undefined && qtd_seme > 0 && (
                  <C.BtnAdd
                    onClick={() =>
                      togglePopup('Adubação de semeadura', 'SEMEADURA')
                    }
                    style={{ marginRight: '20px' }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={'#333'} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_seme}
                  onValueChange={setQtdSeme}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Número de aplicações (*)'
                  width='200px'
                  height={50}
                  style={{ marginLeft: '20px' }}
                />
              </C.BoxButton>

              {nutri.SEMEADURA !== undefined && nutri.SEMEADURA.length > 0 && (
                <DataGrid
                  dataSource={nutri.SEMEADURA}
                  focusedRowEnabled={false}
                  hoverStateEnabled={true}
                  keyExpr={'id'}
                >
                  <Column caption={'N'} dataField={'nitrogenio'} />
                  <Column caption={'P2OS'} dataField={'fosforo'} />
                  <Column caption={'K2O'} dataField={'potassio'} />
                  <Column
                    caption={'Estádio Fenológico'}
                    dataField={'estadio_veget_feno'}
                  />
                  <Column caption={'Dose (kg/ha)'} dataField={'dose'} />
                  <Column
                    caption={'Unid. Medida'}
                    dataField={'dose_un_medida'}
                  />
                  <Column caption={'Fabricante'} dataField={'fabricante'} />
                  <Column
                    caption={'#'}
                    alignment={'center'}
                    cellRender={handleButtonsActions}
                  />
                </DataGrid>
              )}

              <C.Separator />

              <C.BoxButton>
                <C.Label>
                  Adubação de cobertura{' '}
                  <span style={{ color: '#2F80ED' }}>
                    <Tooltip
                      info={HelpAdubacaoCobertura}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_cobertura !== undefined && qtd_cobertura > 0 && (
                  <C.BtnAdd
                    onClick={() =>
                      togglePopup('Adubação de cobertura', 'COBERTURA')
                    }
                    style={{ marginRight: '20px' }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={'#333'} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_cobertura}
                  onValueChange={setQtdCobertura}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Número de aplicações (*)'
                  width='200px'
                  height={50}
                  style={{ marginLeft: '20px' }}
                />
              </C.BoxButton>

              {nutri.COBERTURA !== undefined && nutri.COBERTURA.length > 0 && (
                <DataGrid
                  dataSource={nutri.COBERTURA}
                  focusedRowEnabled={false}
                  hoverStateEnabled={true}
                  keyExpr={'id'}
                >
                  <Column caption={'N'} dataField={'nitrogenio'} />
                  <Column caption={'P2OS'} dataField={'fosforo'} />
                  <Column caption={'K2O'} dataField={'potassio'} />
                  <Column
                    caption={'Estádio Fenológico'}
                    dataField={'estadio_veget_feno'}
                  />
                  <Column caption={'Dose (kg/ha)'} dataField={'dose'} />
                  <Column
                    caption={'Unid. Medida'}
                    dataField={'dose_un_medida'}
                  />
                  <Column caption={'Fabricante'} dataField={'fabricante'} />
                  <Column
                    caption={'#'}
                    alignment={'center'}
                    cellRender={handleButtonsActions}
                  />
                </DataGrid>
              )}

              <C.Separator />

              <C.BoxButton>
                <C.Label>
                  Adubação foliar{' '}
                  <span style={{ color: '#2F80ED' }}>
                    <Tooltip
                      info={HelpAdubacaoFoliar}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_foliar !== undefined && qtd_foliar > 0 && (
                  <C.BtnAdd
                    onClick={() => togglePopup('Adubação foliar', 'FOLIAR')}
                    style={{ marginRight: '20px' }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={'#333'} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_foliar}
                  onValueChange={setQtdFoliar}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Número de aplicações (*)'
                  width='200px'
                  height={50}
                  style={{ marginLeft: '20px' }}
                />
              </C.BoxButton>

              {nutri.FOLIAR !== undefined && nutri.FOLIAR.length > 0 && (
                <DataGrid
                  dataSource={nutri.FOLIAR}
                  focusedRowEnabled={false}
                  hoverStateEnabled={true}
                  keyExpr={'id'}
                >
                  <Column caption={'N'} dataField={'nitrogenio'} />
                  <Column caption={'P2OS'} dataField={'fosforo'} />
                  <Column caption={'K2O'} dataField={'potassio'} />
                  <Column
                    caption={'Estádio Fenológico'}
                    dataField={'estadio_veget_feno'}
                  />
                  <Column caption={'Dose (kg/ha)'} dataField={'dose'} />
                  <Column
                    caption={'Unid. Medida'}
                    dataField={'dose_un_medida'}
                  />
                  <Column caption={'Fabricante'} dataField={'fabricante'} />
                  <Column
                    caption={'#'}
                    alignment={'center'}
                    cellRender={handleButtonsActions}
                  />
                </DataGrid>
              )}

              <C.Separator />

              <C.BoxButton>
                <C.Label>
                  Especialidades/Fisiologia (condicionadores de solo,
                  estimulantes, etc){' '}
                  <span style={{ color: '#2F80ED' }}>
                    <Tooltip
                      info={HelpAdubacaoFisioVegetal}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_espec_fisio !== undefined && qtd_espec_fisio > 0 && (
                  <C.BtnAdd
                    onClick={() =>
                      togglePopup('Especialidade/Fisiologia', 'ESPECIALIDADES')
                    }
                    style={{ marginRight: '20px' }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={'#333'} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_espec_fisio}
                  onValueChange={setQtdEspecFisio}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Número de aplicações (*)'
                  width='200px'
                  height={50}
                  style={{ marginLeft: '20px' }}
                />
              </C.BoxButton>

              {nutri.ESPECIALIDADES !== undefined &&
                nutri.ESPECIALIDADES.length > 0 && (
                  <DataGrid
                    dataSource={nutri.ESPECIALIDADES}
                    focusedRowEnabled={false}
                    hoverStateEnabled={true}
                    keyExpr={'id'}
                  >
                    <Column caption={'N'} dataField={'nitrogenio'} />
                    <Column caption={'P2OS'} dataField={'fosforo'} />
                    <Column caption={'K2O'} dataField={'potassio'} />
                    <Column
                      caption={'Estádio Fenológico'}
                      dataField={'estadio_veget_feno'}
                    />
                    <Column caption={'Dose (kg/ha)'} dataField={'dose'} />
                    <Column
                      caption={'Unid. Medida'}
                      dataField={'dose_un_medida'}
                    />
                    <Column caption={'Fabricante'} dataField={'fabricante'} />
                    <Column
                      caption={'#'}
                      alignment={'center'}
                      cellRender={handleButtonsActions}
                    />
                  </DataGrid>
                )}
            </C.ContentRightFormBoxLeft>
          </C.BoxFields>
          <br />

          <C.BoxButtonSubmit>
            <Button
              onClick={() => navigate('/passo9b')}
              title='VOLTAR'
              color='#2F80ED'
              iconLeft={<BiChevronLeft fontSize={22} />}
            />
            <Button
              onClick={handleSubmitFinishStep}
              title='SALVAR E AVANÇAR'
              color='#27AE60'
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </C.BoxButtonSubmit>
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
