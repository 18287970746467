import * as C from './styles';

type Props = {
    title: string;
}

export const Title = ({ title }: Props) => {
    return(
        <C.Container>
            {title}
        </C.Container>
    );
}