import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 300px;
    color: #254D6B;
    font-size: 1.5rem;
    font-weight: normal;
    justify-content: center;
    align-items: center;
    
    button {
        display: flex;
        color: #fff;
        background-color: #27AE60;
        padding: 0.8rem 1rem;
        border: none;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        margin-top: 65px;
        font-size: 1rem;
        cursor: pointer;

            font-weight: bold;
    }
`;

export const BoxError = styled.div`
    display: flex;
    flex-direction: row;
    align-items:center;
    font-size: 2rem;

    div {
        font-size: 5rem;
        margin-left: 10px;
    }
`;

export const AreaText = styled.div`
    white-space: nowrap;
    overflow: hidden;

    h2 {
        animation: blinkCursor 500ms steps(40) infinite normal, typing 4s steps(40) infinite normal both;
    }

    @keyframes typing {
        from {
            width: 0;
        }
        to {
            width: 15em;
        }
    }

    @keyframes blinkCursor {
        from {
            border-right-color: rgb(255,255,255);
        }
        to {
            border-right-color: transparent;
        }
    }
`;