/* eslint-disable @typescript-eslint/no-unused-vars */
import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiChevronLeft, BiChevronRight, BiPlusCircle } from 'react-icons/bi';

import 'devextreme/dist/css/dx.light.css';
import { NumberBox, SelectBox, TextBox } from 'devextreme-react';
import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '../../components/Tooltip';
import { HelpTsiOnFarm, OndeRealizou } from '../../data/data';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { useAppStore } from '../../store';
import maquinarioData from '../../data/maquinario.json';
import AutoCompleteCustom, {
  IDefensivo,
  IMaquinario,
} from '../../components/AutoCompleteCustom';
import defensivosData from '../../data/defensivos.json';
import NumericTextBox from '../../components/NumericTextBox';

export const FormStep9b = () => {
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);

  const navigate = useNavigate();
  const [calagem_frequency, setCalagemFrequency] = useState(
    subscription.area_info?.calagem_frequency
  );
  const [gessagem_frequency, setGessagemFrequency] = useState(
    subscription.area_info?.gessagem_frequency
  );
  const [descompactacao_frequency, setDescompactacaoFrequency] = useState(
    subscription.area_info?.descompactacao_frequency
  );

  const [sistema_producao, setSistemaProducao] = useState(
    subscription.culture_info?.sistema_producao
  );

  const [espaco_linha, setEspacoLinha] = useState(
    subscription.culture_info?.espaco_linha
  );
  const [numero_semente, setNumberSemente] = useState(
    subscription.culture_info?.numero_semente
  );
  const [realiza_refugio, setRealizaRefugio] = useState(
    subscription.culture_info?.realiza_refugio
  );
  const [hibrido_refugio, setHibriRefugio] = useState(
    subscription.culture_info?.hibrido_refugio
  );
  const [tratamento_semente, setTratSement] = useState(
    subscription.culture_info?.tratamento_semente
  );
  const [nome_pacte_trat_sement, setPctTratSemente] = useState(
    subscription.culture_info?.nome_pacte_trat_sement
  );
  const [nome_pacte_trat_sement_2, setPctTratSemente2] = useState(
    subscription.culture_info?.nome_pacte_trat_sement
  );
  const [nome_pacte_trat_sement_3, setPctTratSemente3] = useState(
    subscription.culture_info?.nome_pacte_trat_sement
  );
  const [nome_pacte_trat_sement_4, setPctTratSemente4] = useState(
    subscription.culture_info?.nome_pacte_trat_sement
  );
  const [nome_pacte_trat_inocul, setPctTratInocul] = useState(
    subscription.culture_info?.nome_pacte_trat_inocul
  );
  const [nome_pacte_trat_bioestim, setPctTratBioestim] = useState(
    subscription.culture_info?.nome_pacte_trat_bioestim
  );

  const [ano_semeadora, setAnoSemeadora] = useState(
    subscription.culture_info?.ano_semeadora === ' '
      ? undefined
      : subscription.culture_info?.ano_semeadora
  );
  const [modelo_semeadora, setMarcaSemeadora] = useState(
    subscription.culture_info?.modelo_semeadora === ' '
      ? undefined
      : subscription.culture_info?.modelo_semeadora
  );
  const [modelo_pulverizador, setMarcaPulverizador] = useState(
    subscription.culture_info?.modelo_pulverizador === ' '
      ? undefined
      : subscription.culture_info?.modelo_pulverizador
  );
  const [ano_pulverizador, setAnoPulverizador] = useState(
    subscription.culture_info?.ano_pulverizador === ' '
      ? undefined
      : subscription.culture_info?.ano_pulverizador
  );

  const [fabricante_semeadora, setFabricanteSemeadora] = useState(
    subscription.culture_info?.fabricante_semeadora === ' '
      ? undefined
      : subscription.culture_info?.fabricante_semeadora
  );
  const [fabricante_pulverizador, setFabricantePulverizador] = useState(
    subscription.culture_info?.fabricante_pulverizador === ' '
      ? undefined
      : subscription.culture_info?.fabricante_pulverizador
  );
  const [fabricante_colhedora, setFabricanteColhedora] = useState(
    subscription.culture_info?.fabricante_colhedora === ' '
      ? undefined
      : subscription.culture_info?.fabricante_colhedora
  );
  const [modelo_colhedora, setModeloColhedora] = useState(
    subscription.culture_info?.modelo_colhedora === ' '
      ? undefined
      : subscription.culture_info?.modelo_colhedora
  );
  const [ano_colhedora, setAnoColhedora] = useState(
    subscription.culture_info?.ano_colhedora === ' '
      ? undefined
      : subscription.culture_info?.ano_colhedora
  );

  const [qtd_defensivos, setQtdDefensivos] = useState(
    subscription.culture_info?.qtd_defensivos
  );

  const handleSubmit = useCallback(async () => {
    if (
      tratamento_semente !== undefined &&
      !tratamento_semente.includes('3') &&
      (nome_pacte_trat_bioestim === undefined ||
        nome_pacte_trat_inocul === undefined ||
        nome_pacte_trat_sement === undefined ||
        nome_pacte_trat_bioestim === '' ||
        nome_pacte_trat_inocul === '' ||
        nome_pacte_trat_sement === '' ||
        nome_pacte_trat_bioestim === null ||
        nome_pacte_trat_inocul === null ||
        nome_pacte_trat_sement === null)
    ) {
      return toast.error('Preencha os campos de tratamento de sementes!');
    }

    if (
      ano_semeadora === null ||
      modelo_semeadora === null ||
      modelo_pulverizador === null ||
      ano_pulverizador === null ||
      fabricante_semeadora === null ||
      fabricante_pulverizador === null ||
      fabricante_colhedora === null ||
      modelo_colhedora === null ||
      ano_colhedora === null ||
      ano_semeadora === undefined ||
      modelo_semeadora === undefined ||
      modelo_pulverizador === undefined ||
      ano_pulverizador === undefined ||
      fabricante_semeadora === undefined ||
      fabricante_pulverizador === undefined ||
      fabricante_colhedora === undefined ||
      modelo_colhedora === undefined ||
      ano_colhedora === undefined ||
      ano_semeadora === '' ||
      modelo_semeadora === '' ||
      modelo_pulverizador === '' ||
      ano_pulverizador === '' ||
      fabricante_semeadora === '' ||
      fabricante_pulverizador === '' ||
      fabricante_colhedora === '' ||
      modelo_colhedora === '' ||
      ano_colhedora === ''
    ) {
      return toast.error('Preencha todos os campos de maquinário!');
    }

    api
      .post('/subscription/area-seeds-machines', {
        subscription_id: subscription.id,
        step: 9,
        espaco_linha,
        numero_semente,
        realiza_refugio,
        hibrido_refugio,
        tratamento_semente,
        qtd_defensivos,
        nome_pacte_trat_sement,
        nome_pacte_trat_sement_2,
        nome_pacte_trat_sement_3,
        nome_pacte_trat_sement_4,
        nome_pacte_trat_inocul,
        nome_pacte_trat_bioestim,
        ano_semeadora,
        modelo_semeadora,
        modelo_pulverizador,
        ano_pulverizador,

        fabricante_semeadora,
        fabricante_pulverizador,
        fabricante_colhedora,
        modelo_colhedora,
        ano_colhedora,
      })
      .then((res) => {
        if (res.status === 201) {
          setSubscription(res.data);
          setStep(9);
          toast.success('Dados salvos com sucesso!');
          navigate('/passo10');
        }
      })
      .catch(() => toast.error('Ops! Ocorreu um erro, tente novamente'));
  }, [
    navigate,
    setStep,
    subscription,
    espaco_linha,
    numero_semente,
    realiza_refugio,
    hibrido_refugio,
    tratamento_semente,
    qtd_defensivos,
    nome_pacte_trat_sement,
    nome_pacte_trat_sement_2,
    nome_pacte_trat_sement_3,
    nome_pacte_trat_sement_4,
    nome_pacte_trat_inocul,
    nome_pacte_trat_bioestim,
    ano_semeadora,
    modelo_semeadora,
    modelo_pulverizador,
    ano_pulverizador,
    fabricante_semeadora,
    fabricante_pulverizador,
    fabricante_colhedora,
    modelo_colhedora,
    ano_colhedora,
    setSubscription,
  ]);

  const subscription_id = localStorage.getItem('@GetapAPP:subscription_id');

  useEffect(() => {
    if (
      subscription_id !== null &&
      Number(subscription_id) !== subscription.id
    ) {
      loadSubscription(Number(subscription_id));
    }
    setCalagemFrequency(subscription.area_info?.calagem_frequency);
    setGessagemFrequency(subscription.area_info?.gessagem_frequency);
    setDescompactacaoFrequency(
      subscription.area_info?.descompactacao_frequency
    );
    setSistemaProducao(subscription.culture_info?.sistema_producao);
    setEspacoLinha(subscription.culture_info?.espaco_linha);
    setNumberSemente(subscription.culture_info?.numero_semente);
    setRealizaRefugio(subscription.culture_info?.realiza_refugio);
    setHibriRefugio(subscription.culture_info?.hibrido_refugio);
    setTratSement(
      subscription.culture_info?.tratamento_semente || '3 - Não realizou'
    );
    setQtdDefensivos(subscription.culture_info?.qtd_defensivos);
    setPctTratSemente(subscription.culture_info?.nome_pacte_trat_sement);
    setPctTratSemente2(subscription.culture_info?.nome_pacte_trat_sement_2);
    setPctTratSemente3(subscription.culture_info?.nome_pacte_trat_sement_3);
    setPctTratSemente4(subscription.culture_info?.nome_pacte_trat_sement_4);

    setPctTratInocul(subscription.culture_info?.nome_pacte_trat_inocul);
    setPctTratBioestim(subscription.culture_info?.nome_pacte_trat_bioestim);
    setAnoSemeadora(
      subscription.culture_info?.ano_semeadora === ' '
        ? undefined
        : subscription.culture_info?.ano_semeadora
    );
    setMarcaSemeadora(
      subscription.culture_info?.modelo_semeadora === ' '
        ? ''
        : subscription.culture_info?.modelo_semeadora
    );
    setMarcaPulverizador(
      subscription.culture_info?.modelo_pulverizador === ' '
        ? ''
        : subscription.culture_info?.modelo_pulverizador
    );
    setAnoPulverizador(
      subscription.culture_info?.ano_pulverizador === ' '
        ? undefined
        : subscription.culture_info?.ano_pulverizador
    );
    setFabricanteSemeadora(
      subscription.culture_info?.fabricante_semeadora === ' '
        ? undefined
        : subscription.culture_info?.fabricante_semeadora
    );
    setFabricantePulverizador(
      subscription.culture_info?.fabricante_pulverizador === ' '
        ? undefined
        : subscription.culture_info?.fabricante_pulverizador
    );
    setFabricanteColhedora(
      subscription.culture_info?.fabricante_colhedora === ' '
        ? undefined
        : subscription.culture_info?.fabricante_colhedora
    );
    setModeloColhedora(
      subscription.culture_info?.modelo_colhedora === ' '
        ? undefined
        : subscription.culture_info?.modelo_colhedora
    );
    setAnoColhedora(
      subscription.culture_info?.ano_colhedora === ' '
        ? undefined
        : subscription.culture_info?.ano_colhedora
    );
  }, [loadSubscription, subscription, subscription_id]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.ContentRightForm>
          <C.HeaderContentRight>
            <Title title='Informações da área inscrita: Implantação da cultura' />
            <span>(*) Campos obrigatórios</span>
          </C.HeaderContentRight>
          <br />
          <br />
          <C.BoxFields>
            <C.ContentRightFormBoxLeft>
              <C.InfoSection>Tratamento de sementes</C.InfoSection>
              <C.GroupInputs>
                <C.BoxInput>
                  <div
                    style={{
                      marginLeft: '100px',
                      marginBottom: '-20px',
                      position: 'absolute',
                      zIndex: '1000',
                      color: '#2F80ED',
                    }}
                  >
                    <Tooltip
                      info={HelpTsiOnFarm}
                      icon={<AiFillQuestionCircle />}
                    />
                  </div>
                  <SelectBox
                    value={tratamento_semente}
                    onValueChange={(e) => setTratSement(e)}
                    items={OndeRealizou}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Onde realizou? (*)'
                    placeholder='Selecione'
                    width='100%'
                    height={50}
                  />
                </C.BoxInput>

                {tratamento_semente !== undefined &&
                  tratamento_semente !== null &&
                  tratamento_semente !== '' &&
                  !(tratamento_semente.indexOf('3') >= 0) && (
                    <>
                      <C.BoxInput>
                        <TextBox
                          value={nome_pacte_trat_inocul}
                          onValueChange={(e) => setPctTratInocul(e)}
                          stylingMode='underlined'
                          labelMode='static'
                          label='Inoculantes (*)'
                          width='100%'
                          height={50}
                        />
                      </C.BoxInput>

                      <C.BoxInput>
                        <TextBox
                          value={nome_pacte_trat_bioestim}
                          onValueChange={(e) => setPctTratBioestim(e)}
                          stylingMode='underlined'
                          labelMode='static'
                          label='Bioestimulantes (*)'
                          width='100%'
                          height={50}
                        />
                      </C.BoxInput>

                      <C.BoxInputWithBtnPlus>
                        <NumberBox
                          value={
                            qtd_defensivos !== undefined
                              ? qtd_defensivos
                              : undefined
                          }
                          onValueChange={setQtdDefensivos}
                          stylingMode='underlined'
                          labelMode='static'
                          label='Quantidade de defensivos '
                          width='50%'
                          height={50}
                          max={4}
                        />
                        <span>
                          <BiPlusCircle
                            fontSize={28}
                            style={{ marginRight: 10 }}
                            onClick={() => {}}
                          />
                        </span>
                      </C.BoxInputWithBtnPlus>

                      {Number(qtd_defensivos) >= 1 && (
                        <C.BoxInput>
                          <AutoCompleteCustom<IDefensivo>
                            itemsAvailable={defensivosData}
                            valueExpr='marca_comercial'
                            searchExpr='marca_comercial'
                            inputValue={nome_pacte_trat_sement}
                            label='Defensivo 1'
                            // onValueChanged={(e) => setPctTratSemente(e.value)}
                            labelMode='static'
                            stylingMode='underlined'
                            height={50}
                            width={200}
                            searchMode='startswith'
                            typeData='defensivo'
                            onValueChanged={(e) => setPctTratSemente(e)}
                          />
                        </C.BoxInput>
                      )}

                      {Number(qtd_defensivos) >= 2 && (
                        <C.BoxInput>
                          <AutoCompleteCustom<IDefensivo>
                            itemsAvailable={defensivosData}
                            valueExpr='marca_comercial'
                            searchExpr='marca_comercial'
                            inputValue={nome_pacte_trat_sement_2}
                            label='Defensivo 2'
                            // onValueChanged={(e) => setPctTratSemente2(e.value)}
                            labelMode='static'
                            stylingMode='underlined'
                            height={50}
                            width={200}
                            typeData='defensivo'
                            searchMode='startswith'
                            onValueChanged={(e) => setPctTratSemente2(e)}
                          />
                        </C.BoxInput>
                      )}

                      {Number(qtd_defensivos) >= 3 && (
                        <C.BoxInput>
                          <AutoCompleteCustom<IDefensivo>
                            itemsAvailable={defensivosData}
                            valueExpr='marca_comercial'
                            searchExpr='marca_comercial'
                            inputValue={nome_pacte_trat_sement_3}
                            label='Defensivo 3'
                            // onValueChanged={(e) => setPctTratSemente3(e.value)}
                            labelMode='static'
                            stylingMode='underlined'
                            height={50}
                            width={200}
                            typeData='defensivo'
                            searchMode='startswith'
                            onValueChanged={(e) => setPctTratSemente3(e)}
                          />
                        </C.BoxInput>
                      )}

                      {Number(qtd_defensivos) >= 4 && (
                        <C.BoxInput>
                          <AutoCompleteCustom<IDefensivo>
                            itemsAvailable={defensivosData}
                            valueExpr='marca_comercial'
                            searchExpr='marca_comercial'
                            inputValue={nome_pacte_trat_sement_4}
                            label='Defensivo 4'
                            labelMode='static'
                            stylingMode='underlined'
                            height={50}
                            width={200}
                            typeData='defensivo'
                            searchMode='startswith'
                            onValueChanged={(e) => setPctTratSemente4(e)}
                          />
                        </C.BoxInput>
                      )}
                    </>
                  )}
              </C.GroupInputs>

              <C.HeaderContentRight>
                <Title title='Maquinário' />
              </C.HeaderContentRight>
              <br />
              <br />
              <C.InfoSection>Semeadora</C.InfoSection>

              <C.GroupInputs2>
                <C.BoxInput>
                  <AutoCompleteCustom<IMaquinario>
                    itemsAvailable={maquinarioData}
                    valueExpr='Nome_Fabricante'
                    searchExpr='Nome_Fabricante'
                    inputValue={fabricante_semeadora}
                    label='Fabricante da semeadora (*)'
                    labelMode='static'
                    stylingMode='underlined'
                    height={50}
                    typeData='maquinario'
                    searchMode='startswith'
                    onValueChanged={(e) => setFabricanteSemeadora(e)}
                    // onValueChanged={handleValueChangeMaquinario(e, setFabricanteSemeadora(e.value))}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  <TextBox
                    value={modelo_semeadora}
                    onValueChange={(e) => setMarcaSemeadora(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Modelo da semeadora(*)'
                    width='100%'
                    height={50}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  {/* <NumberBox
                    value={Number(ano_semeadora)}
                    onValueChange={(e) => setAnoSemeadora(e)}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Ano da semeadora(*)"
                    width="100%"
                    height={50}
                  /> */}
                  <NumericTextBox
                    inputValue={ano_semeadora}
                    onValueChange={(e) => setAnoSemeadora(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Ano da semeadora (*)'
                    width='100%'
                    height={50}
                    maxLength={4}
                  />
                </C.BoxInput>
              </C.GroupInputs2>

              <br />
              <br />
              <C.InfoSection>Pulverizador</C.InfoSection>

              <C.GroupInputs2>
                <C.BoxInput>
                  <AutoCompleteCustom<IMaquinario>
                    itemsAvailable={maquinarioData}
                    valueExpr='Nome_Fabricante'
                    searchExpr='Nome_Fabricante'
                    inputValue={fabricante_pulverizador}
                    label='Fabricante do pulverizador (*)'
                    labelMode='static'
                    stylingMode='underlined'
                    height={50}
                    searchMode='startswith'
                    typeData='maquinario'
                    onValueChanged={(e) => setFabricantePulverizador(e)}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  <TextBox
                    value={modelo_pulverizador}
                    onValueChange={(e) => setMarcaPulverizador(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Modelo do pulverizador (*)'
                    width='100%'
                    height={50}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  {/* <NumberBox
                    value={Number(ano_pulverizador)}
                    onValueChange={(e) => setAnoPulverizador(e)}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Ano do pulverizador (*)"
                    width="100%"
                    height={50}
                  /> */}
                  <NumericTextBox
                    inputValue={ano_pulverizador}
                    onValueChange={(e) => setAnoPulverizador(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Ano do pulverizador (*)'
                    width='100%'
                    height={50}
                    maxLength={4}
                  />
                </C.BoxInput>
              </C.GroupInputs2>

              <br />
              <br />
              <C.InfoSection>Colhedora</C.InfoSection>

              <C.GroupInputs2>
                <C.BoxInput>
                  <AutoCompleteCustom<IMaquinario>
                    itemsAvailable={maquinarioData}
                    valueExpr='Nome_Fabricante'
                    searchExpr='Nome_Fabricante'
                    inputValue={fabricante_colhedora}
                    label='Fabricante da colhedora (*)'
                    labelMode='static'
                    stylingMode='underlined'
                    height={50}
                    searchMode='startswith'
                    typeData='maquinario'
                    onValueChanged={(e) => setFabricanteColhedora(e)}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  <TextBox
                    value={modelo_colhedora}
                    onValueChange={(e) => setModeloColhedora(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Modelo da colhedora (*)'
                    width='100%'
                    height={50}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  {/* <NumberBox
                    value={Number(ano_colhedora)}
                    onValueChange={(e) => setAnoColhedora(e)}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Ano da colhedora (*)"
                    width="100%"
                    height={50}
                  /> */}
                  <NumericTextBox
                    inputValue={ano_colhedora}
                    onValueChange={(e) => setAnoColhedora(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Ano da colhedora (*)'
                    width='100%'
                    height={50}
                    maxLength={4}
                  />
                </C.BoxInput>
              </C.GroupInputs2>
            </C.ContentRightFormBoxLeft>
          </C.BoxFields>
          <C.BoxButtonSubmit>
            <Button
              onClick={() => navigate('/passo9')}
              title='VOLTAR'
              color='#2F80ED'
              iconLeft={<BiChevronLeft fontSize={22} />}
            />
            <Button
              onClick={handleSubmit}
              title='SALVAR E AVANÇAR'
              color='#27AE60'
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </C.BoxButtonSubmit>
          <br />
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
