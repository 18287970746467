import React from 'react';
import TextBox from 'devextreme-react/text-box';
import { EditorStyle, LabelMode } from 'devextreme/common';
import { NativeEventInfo } from 'devextreme/events';
import { ValueChangedInfo } from 'devextreme/ui/editor/editor';

interface NumericTextBoxProps {
    inputValue : string | undefined, 
    // setInputValue : React.Dispatch<React.SetStateAction<string | undefined>>,
    placeholder? : string | undefined,
    width?: number | string | (() => number | string),
    height?: number | string | (() => number | string),
    labelMode?: LabelMode,
    label? : string | undefined,
    mask?: string | undefined,
    stylingMode?: EditorStyle,
    maxLength?: string | number,
    onValueChange?: (value: any) => void,
    onValueChanged?: ((e: NativeEventInfo<unknown, KeyboardEvent | MouseEvent | PointerEvent | Event> & ValueChangedInfo) => void)
}

const NumericTextBox: React.FC<NumericTextBoxProps> = (
    {
        inputValue,
        // setInputValue,
        placeholder,
        width,
        height,
        labelMode,
        label,
        mask,
        stylingMode,
        maxLength,
        onValueChange,
        onValueChanged
    }
    
) => {

  const handleKeyDown = (event: any) => {
    const char = String.fromCharCode(event.event.keyCode || event.event.which);
    const validChars = /^[0-9.,+-]$/;
    console.log(`event.keyCode = ${event.event.keyCode}   -->   char = ${char}   -->  validChars.test(char) = ${validChars.test(char)}`);
    if (!validChars.test(char) 
        && event.event.key !== 'Backspace'
        && event.event.key !== '-'
        && event.event.key !== ','
        && event.event.key !== '+'
        && event.event.key !== '.') {
      event.event.preventDefault();
    }
  };

//   // Função para validar o valor final do input
//   const handleValueChanged = (e: any) => {
//     const value = e.value;

//     // Substitui vírgula por ponto se preferir
//     const sanitizedValue = value.replace(/,/g, '.');

//     // setInputValue(sanitizedValue);
//     setInputValue(sanitizedValue);
//   };

  return (
    <TextBox
      value={inputValue}
      onValueChanged={onValueChanged}
    //   valueChangeEvent= "keyup"
         onKeyDown={handleKeyDown}     
         label={label}  
        placeholder={placeholder}
        width={width}
        height={height}
        labelMode={labelMode}
        mask={mask}
        stylingMode={stylingMode}
        maxLength={maxLength}
        onValueChange={onValueChange}
    />
  );
};

export default NumericTextBox;