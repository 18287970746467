import { ReactNode } from 'react';
import * as C from './styles';

type Props = {
    info: string;
    icon?: ReactNode;
}

export const Tooltip = ({ info, icon }: Props) => {
    return(
        <C.Tooltip>
            {icon}
            <C.TooltipText>{info}</C.TooltipText>
        </C.Tooltip>
    );
}