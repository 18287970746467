import styled from 'styled-components';

export const Header = styled.header`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 99px;
    color: #FFF;
    background-color: #254D6B;

    ul {
        display: flex;
        width: 45%;
        list-style-type: none;

        li {
            margin-right: 25px;

            a {
                color: #FFF;
                text-decoration: none;
            }
            a.active {
                color: #27AE60;
            }

            @media screen and (max-width:875px){
                margin-right: 18px;
            }
        }

        @media screen and (max-width:830px){
            width: 100%;
            justify-content: center;
            font-size: .8rem;
            padding: 1%;
            margin-bottom: 10px;
        }
    }

    @media screen and (max-width:760px){
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height:120px;
    }
`;

export const Logo = styled.div`
    img {
        @media screen and (max-width:875px){
            width: 170px;
        }

        @media screen and (max-width:760px ) {
            width: 120px;
            height: 40px;
            margin-top: -10px;
        }
    }
`;


export const CtaSubscribe = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    color: #888;
    border-radius: 32px;
    background-color: #fff;
    transition: filter 0.3s;

    span {
        margin-left: 20px;
        @media screen and (max-width:760px){
            font-size: 14px;
        }
    }

    &:hover{
        filter: brightness(0.9);
    }


    button{
        border: none;
        padding: 0.5rem;
        color: #FFF;
        cursor: pointer;
        background-color: #27AE60;
        height: 36px;
        margin-left: 8px;
        border-radius: 36px;

        a {
            text-decoration: none;
            color: #FFF;
        }
    }


    @media screen and (max-width:875px) {
        width:450px;
        font-size: .8rem;
    }

    @media screen and (max-width:760px){
        height: 35px;
    }
`;



export const CtaLogout = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    color: #888;
    border-radius: 32px;
    background-color: #27AE60;
    border: none;
    padding: 0.5rem;
    color: #FFF;
    cursor: pointer;
    transition: filter 0.3s;

    span {
        margin-left: 20px;
        @media screen and (max-width:760px){
            font-size: 14px;
        }
    }

    &:hover{
        filter: brightness(0.9);
    }


    @media screen and (max-width:875px) {
        width:450px;
        font-size: .8rem;
    }

    @media screen and (max-width:760px){
        height: 35px;
    }
`;