import styled from 'styled-components';

export const Tooltip = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;

    &:hover > div {
        visibility: visible;
    }
`;

export const TooltipText = styled.div`
    visibility: hidden;
    min-width: 300px;
    background-color: #181820;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    margin: 5px 0 0 5px;
    position: absolute;
    right:10px;
    z-index: 1;
`;