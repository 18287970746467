import * as C from "./styles";
import { Title } from "../../components/Title/Title";
import { Button } from "../../components/Button/Button";
import { BiSpreadsheet } from "react-icons/bi";

import "devextreme/dist/css/dx.light.css";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../store";
import { useEffect } from "react";

export const Initial = () => {
  const navigate = useNavigate();
  const loadSubscriptions = useAppStore((store) => store.loadSubscriptions);

  useEffect(() => {
    loadSubscriptions();
  }, [loadSubscriptions]);

  return (
    <C.Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "80vh",
          padding: "5rem",
        }}
      >
        <C.SeeData>
          <Button
            onClick={() => navigate("/dados-inscricao")}
            title="Verificar inscrição"
            color="#27AE60"
            iconRight={<BiSpreadsheet fontSize={22} />}
          />
        </C.SeeData>
        <C.HeaderContentRight>
          <Title title="Bem vindo ao Getap" />
          <span></span>
        </C.HeaderContentRight>
        <div
          style={{
            marginTop: "1rem",
          }}
        >
          <C.BoxFinal>
            <h3>Inicie uma nova inscrição!</h3>
          </C.BoxFinal>
        </div>
      </div>
    </C.Container>
  );
};
