import styled from 'styled-components';
import bgLogin from '../../assets/svgs/bg-agricultura.svg';

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: calc(100vh);
    padding-bottom: 45px;
    background-image: url(${bgLogin});
    background-repeat: no-repeat;
    background-position: botton;
    background-size: cover;
    background-color: #f1f2f3;
    // background-attachment: fixed;

    h2 {
        font-size: 2rem;
        text-align: center;
        color: #254D6B;
        margin: 45px 0;
    }

    @media screen and (max-width:300px) {
        h2 {
            font-size: 1.5rem;
        }
    }
`;

export const FormLogin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 493px;
    min-height: 416px;
    padding-top: 30px;
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 1px 2px 10px rgba(0,0,0,.4);
    
    @media screen and (max-width:560px ) {
        min-width: 380px;
        padding:25px 2%;
        margin: 0 2%;
    }

    @media screen and (max-width:380px ) {
        min-width: 350px;
        padding:25px 2%;
        margin: 0 2%;
    }

    @media screen and (max-width:300px) {
        min-width:270px;
        padding:25px 3%;
        margin: 0 4%;
    }
`;

export const ContentFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 320px;
`;

export const WrapperCheckbox = styled.div`
    display: flex;
    align-items: center;
    margin:15px 0;

    input[type="checkbox"] {
        min-width:16px;
        min-height: 16px;
        margin-right: 8px;
    }

    span {
        font-size: 15px;
        color: #4F4F4F;
    }
`;

export const ForgotPasswd = styled.span`
    font-size: 15px;
    text-align: center;
    margin:10px;
    
    a {
        color: #4F4F4F;
    }
`;