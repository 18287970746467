import styled from 'styled-components';

type ButtonProps = {
    color: string;
}

export const Button = styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 42px;

    font-size: 15px;
    font-weight: 500;
    color: #FFF; 

    text-decoration: none;
    padding:10px;
    margin-top:10px;
    /* margin-bottom: 20px; */
    
    border: 0;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 1px 2px 8px rgba(0,0,0,.3);
    background-color: ${props => props.color};
    transition: all ease .3s;
    
    &:hover {
        opacity: .8;
    }
    
    @media screen and (max-width:875px){
        font-size: 12px;
        margin-bottom: 0;
        
    }
`;