import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../components/Button/Button";
import { Input } from "../../components/input/Input";
import { useAuth } from "../../hooks/auth";
import * as C from "./styles";

import logo from "../../assets/png/Logo_Getap.png";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signIn, user } = useAuth();
  const navigate = useNavigate();

  const handleLogin = useCallback(() => {
    if (email === "" || password === "") {
      toast.error("Preencha todos os campos!");
    }
    signIn({ email, password });
  }, [email, password, signIn]);

  useEffect(() => {
    if (user) {
      navigate("/initial");
    }
  }, [user, navigate]);

  return (
    <>
      {/* <Header /> */}
      <C.Container>
        {/* <h2>Desafio nacional de máxima <br /> produtividade de milho</h2> */}
        <img
          src={logo}
          alt="Logo Getap"
          width={"25%"}
          style={{
            margin: "2rem 0",
          }}
        />
        <C.FormLogin>
          <C.ContentFormWrapper>
            <Input
              label="Usuário"
              plcholder="nome@dominio.com.br"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <Input
              type="password"
              label="Senha"
              plcholder="************"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />

            <C.WrapperCheckbox>
              <input type="checkbox" />
              <span>Manter-me conectado</span>
            </C.WrapperCheckbox>

            <Button onClick={handleLogin} title="ACESSAR" color="#27AE60" />

            <C.ForgotPasswd>
              <Link to="/recupera-senha">Esqueci minha senha</Link>
            </C.ForgotPasswd>
            <C.ForgotPasswd>
              <Link to="/altera-senha">Alterar senha</Link>
            </C.ForgotPasswd>

            <Button
              onClick={() => navigate("/cadastro")}
              title="NÃO POSSUI CONTA? CRIE UMA AGORA!"
              color="#2F80ED"
            />
          </C.ContentFormWrapper>
        </C.FormLogin>
      </C.Container>
    </>
  );
};
