import * as C from "./styles";
import { Title } from "../../components/Title/Title";
import { Button } from "../../components/Button/Button";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";

import ReactHtmlParser from "react-html-parser";
import { useAppStore } from "../../store";

export const FormStep5 = () => {
  const [termSistem, setTermSistem] = useState("");
  const navigate = useNavigate();

  const subscription = useAppStore((store) => store.subscription);
  const setSubscription = useAppStore((store) => store.setSubscription);
  const setTerm = useAppStore((store) => store.setTerm);
  const setStep = useAppStore((store) => store.setStep);

  const loadTerm = useCallback(async () => {
    api.get("/sistem/terms").then((res) => {
      setTermSistem(res.data.term);
    });
  }, [setTermSistem]);

  useEffect(() => {
    loadTerm();
  }, [loadTerm]);

  const handleSubmitTerms = useCallback(async () => {
    if (!subscription.accept_term) {
      return toast.error("Aceite os termos do regulamento");
    }
    try {
      api
        .post("/subscription/accept_terms", {
          subscription_id: subscription.id,
          step: 5,
          term: subscription.accept_term,
        })
        .then((res) => {
          if (res.status === 201) {
            setSubscription(res.data);
            if (subscription.step < 4) {
              setStep(5);
            }
            toast.success("Termos aceitos com sucesso");
            navigate("/passo6");
          }
        });
    } catch (error) {}
  }, [navigate, setStep, subscription, setSubscription]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title="Regulamento" />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>

        <C.ContentRightForm>
          <C.BoxFields>
            <C.BoxRegulamento>
              <p>{ReactHtmlParser(termSistem)}</p>
            </C.BoxRegulamento>
          </C.BoxFields>

          <C.WrapperCheckbox>
            <input
              type="checkbox"
              checked={subscription.accept_term === 1}
              onChange={(e) => setTerm(e.target.checked)}
            />
            <span>
              Aceito os termos do regulamento do concurso de produtividade GETAP
            </span>
          </C.WrapperCheckbox>

          <C.BoxButtonSubmit>
            <Button
              onClick={() => navigate("/passo4")}
              title="VOLTAR"
              color="#2F80ED"
              iconLeft={<BiChevronLeft fontSize={22} />}
            />
            <Button
              onClick={handleSubmitTerms}
              title="AVANÇAR"
              color="#27AE60"
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </C.BoxButtonSubmit>
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
