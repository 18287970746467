import styled from 'styled-components';

export const Container = styled.div``;

export const Area = styled.div`
    display: flex;
`;

export const Steps = styled.div`
    display: flex;
    flex: 1;
    height: 100vh;
    padding: 20px;
    margin-bottom: 40px;

    @media screen and (max-width:960px){
        flex-direction: column;
    }
`;

export const Sidebar = styled.div`
    width: 350px;

    @media screen and ( max-width: 960px ) {
        width: 100%;
    }
`;

export const Page = styled.div`
    flex: 1;
`;

export const ContentLeft = styled.div`
    width: 100%;
    padding: 20px;
    height: 100%;
    background-color: #F0F0F0;
    border-radius: 6px;
`;

export const BoxInputDate = styled.div`
    margin:20px 0;
    color: #444;

    span {
        font-size: 14px;
        color: #525252;
    }

    select {
        width: 100%;
        margin-top: 10px;
        font-size:16px;
        color: inherit;
        padding:10px 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #CCC;
        background-color: #F0F0F0;
        cursor: pointer;
        outline: 0;
    }
`;

export const CheckSteps = styled.div`
    margin:40px 0;
`;

type Props = {
    color: string;
}

export const ContentStep = styled.div<Props>`
    display: flex;
    align-items: center;
    color: ${props => props.color === '1' ? '#27AE60' : '#525252' };
    font-size: 14px;
    padding:10px 0 10px 15px;
    margin-top:10px;
    border-bottom: 1px solid #ccc;
`;

export const WhatsApp = styled.div`
    position: fixed;
    right: 30px;
    bottom: 30px;
    cursor: pointer;

    a{
        font-size: 3rem;
        color: #27ae60;
    }
`;