import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import 'devextreme/dist/css/dx.light.css';
import { SelectBox, TextBox } from 'devextreme-react';
import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppStore } from '../../store';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { UF } from '../../data/data';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { BiPlus } from 'react-icons/bi';
import municipiosData from '../../data/municipios.json';
import {
  checkIsOnlyNumbers,
  removeSpacesAndSpecialChars,
  validateEmail,
} from '../../utils/StringUtils';
import { Validator, EmailRule, PatternRule } from 'devextreme-react/validator';
import AutoCompleteCustom, {
  IMunicipio,
} from '../../components/AutoCompleteCustom';

export const FormStep3 = () => {
  const navigate = useNavigate();
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const isLoading = useAppStore((store) => store.isLoading);

  const [cpf_cnpj_prod, setCpfCnpjProd] = useState(
    subscription.resp?.cpf_cnpj_prod
  );
  const [is_empresa, setIsEmpresa] = useState(subscription.resp?.is_empresa);
  const [cnpj_empresa, setCnpjEmpresa] = useState(
    subscription.resp?.cnpj_empresa
  );

  //fields popup
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cpf_cnpj, setCpfCnpj] = useState('');
  const [endereco, setEndereco] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');
  const [nome_resp, setNomeResp] = useState('');
  const [phone_resp, setPhoneResp] = useState('');
  const [type, setType] = useState('');
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [popupOperation, setPopupOperation] = useState('');
  const [
    isPopupCPFCNPJAlreadyExistsVisible,
    setIsPopupCPFCNPJAlreadyExistsVisible,
  ] = useState(false);
  const [
    msgPopupCPFCNPJAlreadyExistsVisible,
    setMsgPopupCPFCNPJAlreadyExistsVisible,
  ] = useState('');
  const [nomeExisting, setNomeExisting] = useState('');
  const [cidadeExisting, setCidadeExisting] = useState('');

  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);
  const config = useAppStore((store) => store.config);

  const subscription_id = localStorage.getItem('@GetapAPP:subscription_id');
  const [municipiosPopup, setMunicipiosPopup] = useState(municipiosData);

  const updateDatasource = (e: string) => {
    setMunicipiosPopup(municipiosData.filter((i) => i['UF'] === e));
  };

  useEffect(() => {
    if (
      subscription_id !== null &&
      Number(subscription_id) !== subscription.id
    ) {
      loadSubscription(Number(subscription_id));
    }
    setCpfCnpjProd(subscription.resp?.cpf_cnpj_prod);
    setIsEmpresa(subscription.resp?.is_empresa ?? 0);
  }, [subscription_id, subscription, loadSubscription]);

  const getPopupTitle = () => {
    if (popupOperation === 'producer') {
      return 'Cadastro de Produtores';
    } else {
      return 'Cadastro de Empresas';
    }
  };

  const getPopupOperationName = () => {
    if (popupOperation === 'producer') {
      return 'Produtor';
    } else {
      return 'Empresa';
    }
  };

  const togglePopup = () => setPopupVisibility(!isPopupVisible);

  const clearPopupFields = () => {
    setNome('');
    setEmail('');
    setPhone('');
    setCpfCnpj('');
    setEndereco('');
    setCidade('');
    setUf('');
    setNomeResp('');
    setPhoneResp('');
  };

  const findProducerCompanyByDocument = useCallback(
    async (operation: string) => {
      console.log(
        `operation = ${operation}, cpf_cnpj_prod = ${cpf_cnpj_prod}, cnpj_empresa = ${cnpj_empresa}`
      );
      const xcpf_cnpj = operation === 'producer' ? cpf_cnpj_prod : cnpj_empresa;
      const pure = removeSpacesAndSpecialChars(xcpf_cnpj!);
      console.log('xcpf_cnpj = ' + pure);
      let result;
      await api
        .get(`/subscription/producer-company/${pure}`)
        .then((res) => {
          result = res.data;
          setMsgPopupCPFCNPJAlreadyExistsVisible(
            `O CPF/CNPJ informado já está cadastrado para [ ${res.data.nome} ]`
          );
          setNomeExisting(res.data.nome);
          setCidadeExisting(res.data.cidade);
          setIsPopupCPFCNPJAlreadyExistsVisible(true);
          toast.info(
            `O CPF/CNPJ informado já está cadastrado para [ ${res.data.nome} ]`
          );
        })
        .catch((error) => {
          console.log(error.response.data.message);
          setMsgPopupCPFCNPJAlreadyExistsVisible(
            'O CPF/CNPJ informado ainda não está cadastrado.'
          );
          // setIsPopupCPFCNPJAlreadyExistsVisible(true)
          toast.info('O CPF/CNPJ informado ainda não está cadastrado.');
        });
      return result;
    },
    [cpf_cnpj_prod, cnpj_empresa]
  );

  const validationProducerCompanyByDocument = useCallback(
    async (operation: string) => {
      const xcpf_cnpj = operation === 'producer' ? cpf_cnpj_prod : cnpj_empresa;
      const pure = removeSpacesAndSpecialChars(xcpf_cnpj!);
      let result;
      await api
        .get(`/subscription/producer-company/${pure}`)
        .then((res) => {
          result = res.data;
        })
        .catch((error) => {
          console.log(error);
          toast.info(
            'O CPF/CNPJ informado ainda não está cadastrado. Cadastre-se antes de continuar'
          );
        });
      return result;
    },
    [cpf_cnpj_prod, cnpj_empresa]
  );

  const validateFieldsPopup = () => {
    console.log(
      `NOME = ${nome}, TYPE = ${type} NOME_RESP = ${nome_resp} PHONE_RESP = ${phone_resp}`
    );
    let validateCompanyFields =
      type === 'Empresa' ? nome_resp === '' || phone_resp === '' : true;
    return (
      !(
        nome === '' ||
        email === '' ||
        phone === '' ||
        cpf_cnpj === '' ||
        endereco === '' ||
        !checkIsOnlyNumbers(endereco) ||
        cidade === '' ||
        uf === '' ||
        type === ''
      ) || !validateCompanyFields
    );
  };

  const createProducerCompany = () => {
    setType(popupOperation === 'producer' ? 'Produtor' : 'Empresa');
    console.log(nome);
    console.log(email);
    console.log(phone);
    console.log(cpf_cnpj);
    console.log(endereco);
    console.log(cidade);
    console.log(uf);
    console.log(nome_resp);
    console.log(phone_resp);
    console.log(type);
    if (!validateFieldsPopup()) {
      return toast.error('Atenção! preencha todos os campos.');
    }

    if (!validateEmail(email)) {
      return toast.error('Atenção! Preencha um email válido.');
    }

    const cpfCnpjPure = removeSpacesAndSpecialChars(cpf_cnpj!);

    if (popupOperation === 'producer') {
      if (!cpf.isValid(cpfCnpjPure || '')) {
        return toast.error('Preencha um CPF válido!');
      }
    } else {
      if (!cnpj.isValid(cpfCnpjPure || '')) {
        return toast.error('Preencha um CNPJ válido!');
      }
    }

    api
      .post('/subscription/producer-company', {
        nome,
        email,
        phone,
        cpf_cnpj: cpfCnpjPure,
        endereco,
        cidade,
        uf,
        nome_resp,
        phone_resp,
        type,
      })
      .then((res) => {
        if (res.status === 201) {
          if (popupOperation === 'producer') {
            setCpfCnpjProd(cpf_cnpj);
          } else {
            setCnpjEmpresa(cpf_cnpj);
          }
          setPopupVisibility(false);
          toast.success('Registro criado!');
        }
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`);
      })
      .finally(() => {
        // listProdutoresEmpresas();
      });
  };

  const handleSave = useCallback(async () => {
    console.log(`subscription_id = ${subscription.id}`);
    console.log(`cnpj_empresa = ${cnpj_empresa}`);
    console.log(`cpf_cnpj_prod = ${cpf_cnpj_prod}`);

    if (
      (cnpj_empresa === null || cnpj_empresa === '') &&
      (cpf_cnpj_prod === null || cpf_cnpj_prod === '')
    ) {
      return toast.error('Atenção! preencha todos os campos.');
    }

    let cpfCnpjProdPure: string = '';
    let cnpjPure: string = '';
    if (!is_empresa) {
      cpfCnpjProdPure = removeSpacesAndSpecialChars(cpf_cnpj_prod!);
      if (!cpf.isValid(cpfCnpjProdPure || '')) {
        return toast.error('Preencha um CPF válido!');
      }
    } else {
      cnpjPure = removeSpacesAndSpecialChars(cnpj_empresa!);
      if (!cnpj.isValid(cnpjPure || '')) {
        return toast.error('Preencha um CNPJ válido!');
      }
    }

    const valid = await validationProducerCompanyByDocument(
      is_empresa === 1 ? 'company' : 'producer'
    );

    if (valid === undefined) {
      return;
    }

    api
      .post(`/subscription/${subscription.id}/producer-company`, {
        cpf_cnpj_prod: cpfCnpjProdPure,
        is_empresa: is_empresa,
        cnpj_empresa: cnpjPure,
      })
      .then((res) => {
        if (res.status === 200) {
          setSubscription(res.data);
          setStep(3);
          toast.success('Salvo com sucesso');
          navigate('/passo4');
        }
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`);
      });
  }, [
    cpf_cnpj_prod,
    is_empresa,
    cnpj_empresa,
    navigate,
    subscription,
    setSubscription,
    setStep,
    validationProducerCompanyByDocument,
  ]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Dados pessoais e de propriedade' />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>
        {isLoading && (
          <C.ContentRightForm>
            <div
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Title title='Carregando...' />
            </div>
          </C.ContentRightForm>
        )}

        {!isLoading && (
          <C.ContentRightForm>
            <C.WrapperCheckbox>
              <input
                type='checkbox'
                checked={is_empresa === 1}
                onChange={(e) => setIsEmpresa(e.target.checked ? 1 : 0)}
              />
              <span>Desejo me inscrever como empresa</span>
            </C.WrapperCheckbox>

            {/* {btnAddNewProducer()} */}

            <C.BoxFields>
              <C.ContentRightFormBoxLeft>
                <TextBox
                  value={config.safra}
                  // onValueChange={(e) => setNomeCons(e)}
                  stylingMode='underlined'
                  labelMode='static'
                  disabled
                  label='Safra (*)'
                  height={50}
                />
                <br />

                {is_empresa === 0 && (
                  <C.GroupInputs>
                    <C.BoxInput>
                      <TextBox
                        value={cpf_cnpj_prod}
                        onValueChange={(e) => setCpfCnpjProd(e)}
                        stylingMode='underlined'
                        labelMode='static'
                        label='CPF do produtor (*)'
                        height={50}
                        width='100%'
                        maxLength={13}
                        mask='000.000.000-00'
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      {/* <C.BoxButtonSubmit> */}
                      <Button
                        onClick={() => {
                          setPopupOperation('producer');
                          findProducerCompanyByDocument('producer');
                        }}
                        title='Verificar'
                        color='gray'
                      />

                      {/* </C.BoxButtonSubmit> */}
                    </C.BoxInput>
                    <C.BoxInput>
                      {/* <C.BoxButtonSubmit> */}
                      <Button
                        onClick={() => {
                          setPopupOperation('producer');
                          setType('Produtor');
                          togglePopup();
                        }}
                        title='Cadastrar'
                        color='gray'
                      />

                      {/* </C.BoxButtonSubmit> */}
                    </C.BoxInput>
                    <br />
                  </C.GroupInputs>
                )}
                {is_empresa === 1 && (
                  <C.ContentRightFormBoxLeft>
                    <C.GroupInputs>
                      <C.BoxInput>
                        {/* <input 
                    type='text'
                    value={cnpj_empresa_temp}
                    className={C.commonInput}
                    onChange={(e) => {
                        console.log(`e = ${e.target.value}`);
                        setCnpjEmpresaTemp(e.target.value);
                        console.log(`cnpj_empresa = ${cnpj_empresa}`);
                        console.log(`cnpj_empresa_temp = ${cnpj_empresa_temp}`);
                        }
                      }
                      onBlur={(e) => {
                        setCnpjEmpresaTemp(e.target.value)
                        console.log(`cnpj_empresa_temp = ${cnpj_empresa_temp}`);
                        }
                      }
                    /> */}

                        <TextBox
                          value={cnpj_empresa}
                          // focusStateEnabled={true}
                          onValueChanged={(e) => {
                            console.log(`e = ${e.value}`);
                            setCnpjEmpresa(e.value);
                            console.log(`cnpj_empresa = ${cnpj_empresa}`);
                          }}
                          onFocusOut={(e) => {
                            console.log(`cnpj_empresa = ${cnpj_empresa}`);
                          }}
                          stylingMode='underlined'
                          labelMode='static'
                          label='CNPJ empresa (*)'
                          height={50}
                          width='100%'
                          maxLength={18}
                          mask='00.000.000/0000-00'
                        />
                      </C.BoxInput>
                      <C.BoxInput>
                        {/* <C.BoxButtonSubmit> */}
                        <Button
                          onClick={() => {
                            findProducerCompanyByDocument('company');
                          }}
                          title='Verificar'
                          color='gray'
                        />
                        {/* </C.BoxButtonSubmit> */}
                      </C.BoxInput>
                      <C.BoxInput>
                        {/* <C.BoxButtonSubmit> */}
                        <Button
                          onClick={() => {
                            setPopupOperation('company');
                            setType('Empresa');
                            togglePopup();
                          }}
                          title='Cadastrar'
                          color='gray'
                        />
                        {/* </C.BoxButtonSubmit> */}
                      </C.BoxInput>
                    </C.GroupInputs>
                    <br />
                    {/* <TextBox
                    value={nome_empresa}
                    onValueChange={(e) => setNomeEmpresa(e)}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Nome empresa (*)"
                    height={50}
                  />
                  <br />
                 
                  <TextBox
                    value={email_empresa}
                    onValueChange={(e) => setEmailEmpresa(e)}
                    stylingMode="underlined"
                    labelMode="static"
                    label="E-mail da empresa (*)"
                    height={50}
                  />
                  <br />
                  <TextBox
                    value={phone_empresa}
                    onValueChange={(e) => setPhoneEmpresa(e)}
                    mask="(00) 0 0000-0000"
                    stylingMode="underlined"
                    labelMode="static"
                    label="Contato da empresa (*)"
                    height={50}
                  />
                  <br />
                  <TextBox
                    value={municipio_empresa}
                    onValueChange={(e) => setMunicipioEmpresa(e)}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Município da empresa (*)"
                    height={50}
                  />
                  <br /> */}
                  </C.ContentRightFormBoxLeft>
                )}
              </C.ContentRightFormBoxLeft>
            </C.BoxFields>

            <C.BoxButtonSubmit>
              <Button
                type='button'
                onClick={() => {
                  setStep(0);
                  navigate(`/passo2`);
                }}
                title='VOLTAR'
                color='#2F80ED'
                iconLeft={<BiChevronLeft fontSize={22} />}
              />
              <Button
                type='button'
                onClick={handleSave}
                title='SALVAR E AVANÇAR'
                color='#27AE60'
                iconRight={<BiChevronRight fontSize={22} />}
              />
            </C.BoxButtonSubmit>
          </C.ContentRightForm>
        )}

        <Popup
          // title='Cadastro de produtores / empresas'
          title={getPopupTitle()}
          visible={isPopupVisible}
          hideOnOutsideClick={true}
          onHiding={(e) => {
            togglePopup();
            clearPopupFields();
          }}
          width={500}
          height={735}
          onShowing={(e) => {
            clearPopupFields();
          }}
        >
          <C.ContentModal>
            <C.ContentModalInput>
              {/* <SelectBox
                                items={producer_company_type}
                                // value={type}
                                onValueChange={(e) => {
                                   setType(e);
                                   console.log(`type = ${type}`)
                                }}
                                stylingMode="underlined"
                                labelMode="static"
                                label="Tipo (*)"
                                width="100%"
                                height={50}
                                // readOnly={true}
                                style={{ marginRight: "20px", color: "#444" }}
                            /> */}
              <TextBox
                stylingMode='underlined'
                value={nome}
                labelMode='static'
                label={`Nome ${getPopupOperationName()}(*)`}
                placeholder='Digite um nome...'
                width={320}
                height={50}
                onValueChanged={(e) => {
                  setNome(e.value);
                  console.log(`nome = ${e.value}`);
                }}
              />
              <TextBox
                stylingMode='underlined'
                value={email}
                labelMode='static'
                label={`Email ${getPopupOperationName()}(*)`}
                placeholder='Email'
                width={320}
                height={50}
                onValueChanged={(e) => setEmail(e.value)}
              >
                <Validator>
                  <EmailRule message='Informe um e-mail  válido!' />
                </Validator>
              </TextBox>
              <TextBox
                stylingMode='underlined'
                value={phone}
                labelMode='static'
                label={`Telefone ${getPopupOperationName()}(*)`}
                placeholder='Um telefone...'
                width={320}
                height={50}
                mask='(00) 0 0000-0000'
                onValueChanged={(e) => setPhone(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={cpf_cnpj}
                labelMode='static'
                // label={`CPF / CNPJ ${getPopupOperationName()}(*)`}
                label={`${
                  type === 'Empresa' ? 'CNPJ Empresa' : 'CPF Produtor'
                } (*)`}
                placeholder='Um CPF ou CNPJ...'
                width={320}
                height={50}
                onValueChanged={(e) => setCpfCnpj(e.value)}
                maxLength={type === 'Empresa' ? 18 : 13}
                mask={
                  type === 'Empresa' ? '00.000.000/0000-00' : '000.000.000-00'
                }
              />
              <TextBox
                stylingMode='underlined'
                value={endereco}
                labelMode='static'
                label={`Endereço ${getPopupOperationName()}(*)`}
                placeholder='Um endereço...'
                width={320}
                height={50}
                onValueChanged={(e) => setEndereco(e.value)}
              >
                <Validator name='Endereço'>
                  <PatternRule
                    message='Informe um Endereço válido!'
                    pattern={/\D/}
                  />
                </Validator>
              </TextBox>
              <SelectBox
                items={UF}
                value={uf}
                onValueChange={(e) => {
                  setUf(e);
                  updateDatasource(e);
                }}
                stylingMode='underlined'
                labelMode='static'
                label={`UF ${getPopupOperationName()}(*)`}
                width='100%'
                height={50}
                style={{ marginRight: '20px', color: '#444' }}
              />
              {/* <TextBox
                                stylingMode='underlined'
                                value={cidade}
                                labelMode='static'
                                label='Cidade'
                                placeholder='Um cidade...'
                                width={320}
                                height={50}
                                onValueChanged={e => setCidade(e.value)}
                            /> */}
              {/* <Autocomplete
                              width={320}
                              height={50}
                              dataSource={municipiosPopup}
                              valueExpr="MUN"
                              searchExpr="MUN"
                              value={cidade}
                              label={`Cidade ${getPopupOperationName()}(*)`}
                              // onValueChanged={(e) => setCidade(e.value)}
                              labelMode="static"
                              stylingMode="underlined"      
                              onValueChanged={handleValueChangeCidade}
                              onFocusOut={handleOnFocusOutCidade}
                            /> */}

              <AutoCompleteCustom<IMunicipio>
                itemsAvailable={municipiosPopup}
                valueExpr='MUN'
                searchExpr='MUN'
                inputValue={cidade}
                label={`Cidade ${getPopupOperationName()}(*)`}
                labelMode='static'
                stylingMode='underlined'
                height={50}
                searchMode='startswith'
                typeData='municipio'
                onValueChanged={(e) => setCidade(e)}
              />

              {type === 'Empresa' && (
                <C.ContentRightFormBoxLeft>
                  <TextBox
                    stylingMode='underlined'
                    value={nome_resp}
                    labelMode='static'
                    label='Nome do Responsável Empresa(*)'
                    placeholder='Um nome de responsável...'
                    width={320}
                    height={50}
                    onValueChanged={(e) => setNomeResp(e.value)}
                  />
                  <TextBox
                    stylingMode='underlined'
                    value={phone_resp}
                    labelMode='static'
                    label='Telefone do Responsável Empresa(*)'
                    placeholder='Um telefone...'
                    width={320}
                    height={50}
                    mask='(00) 00000-0000'
                    onValueChanged={(e) => setPhoneResp(e.value)}
                  />
                </C.ContentRightFormBoxLeft>
              )}
            </C.ContentModalInput>
            <C.BoxButtonSubmitModal>
              <button onClick={createProducerCompany}>
                <BiPlus size={18} />
                Salvar
              </button>
            </C.BoxButtonSubmitModal>
          </C.ContentModal>
        </Popup>

        <Popup
          title='Atenção!'
          visible={isPopupCPFCNPJAlreadyExistsVisible}
          hideOnOutsideClick={true}
          width={340}
          height={280}
          onHidden={(e) => {
            setIsPopupCPFCNPJAlreadyExistsVisible(false);
          }}
          onShowing={(e) => {
            // setIsPopupCPFCNPJAlreadyExistsVisible(false);
            clearPopupFields();
          }}
        >
          <Position at='center' my='center' />
          <ToolbarItem
            widget='dxButton'
            toolbar='bottom'
            location='after'
            render={() => (
              <button
                onClick={() => setIsPopupCPFCNPJAlreadyExistsVisible(false)}
                style={{
                  background: '#2F80ED',
                  border: 'none',
                  padding: '0.5rem 1rem',
                  borderRadius: 5,
                  color: '#fff',
                  cursor: 'pointer',
                }}
              >
                Ok
              </button>
            )}
          />
          <p>{msgPopupCPFCNPJAlreadyExistsVisible}</p>
          <br />
          <TextBox
            label={'Nome:'}
            value={nomeExisting}
            visible={true}
            readOnly={true}
            // labelMode="static"
            stylingMode='underlined'
          />{' '}
          <br />
          <TextBox
            label={'Cidade:'}
            value={cidadeExisting}
            visible={true}
            readOnly={true}
            labelMode='static'
            stylingMode='underlined'
          />{' '}
          <br />
        </Popup>
      </C.ContentRight>
      {/* )} */}
    </C.Container>
  );
};
