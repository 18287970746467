import { EditorStyle, LabelMode, SimplifiedSearchMode } from 'devextreme/common';
import { toast } from "react-toastify";
import Autocomplete from "devextreme-react/autocomplete";

export interface IMaquinario {
    ID_Fabricante: number,
    Nome_Fabricante: string
};

export interface IHibrido {
    ID_Hibrido: string,
    Cultivar: string,
}

export interface IDefensivo {
    Ingrediente: string,
    marca_comercial: string,
    Fabricante: string,
    Classe: string,
    ID_Defensivo: number,
}

export interface IFertilizante {
    razao_social : string,
}

export interface IMunicipio {
    ID_UF: number,
    UF: string,
    ID_MESO: number,
    MESO: string,
    ID_MICRO: string,
    MICRO: string,
    ID_MUN: number,
    MUN: string
}

interface AutoCompleteCustomProps<T> {
    inputValue: string | undefined,
    placeholder?: string | undefined,
    width?: number | string | (() => number | string),
    height?: number | string | (() => number | string),
    labelMode?: LabelMode,
    label?: string | undefined,
    mask?: string | undefined,
    stylingMode?: EditorStyle,
    maxLength?: string | number,
    itemsAvailable: T[],  
    valueExpr: keyof T,   
    searchExpr: string | undefined,
    typeData: string,
    searchMode?: SimplifiedSearchMode,
    onValueChanged?: (value: any) => void,
    onValueChange?: (value: any) => void;
}

const AutoCompleteCustom = <T extends object>({ 
    inputValue,
    placeholder,
    width,
    height,
    labelMode,
    label,
    mask,
    stylingMode,
    maxLength,
    itemsAvailable,
    valueExpr,
    searchExpr,
    searchMode,
    typeData,
    onValueChanged,
    onValueChange
}: AutoCompleteCustomProps<T>) => {

    const findStartsWithItemByFieldG = <T extends object, K extends keyof T>(
        items: T[],
        field: K,
        value: string
      ): T | undefined => {
        if (value === null) {
            return;
        }
        return items.find(item => (item[field] as unknown as string).toLowerCase().startsWith(value.toLowerCase()));
      };

    const findEqualsItemByFieldG = <T extends object, K extends keyof T>(
        items: T[],
        field: K,
        value: string
      ): T | undefined => {
        return items.find(item => (item[field] as unknown as string) === value);
      };

    const findStartsWithItemByField = (value: string): T | undefined => {
        if (value === null) return;
        return findStartsWithItemByFieldG(itemsAvailable, valueExpr, value);
    }

    const findEqualsItemByField = (value: string): T | undefined => {
        if (value === null) return;
        return findEqualsItemByFieldG(itemsAvailable, valueExpr, value);
    }

    const handleValueChange = (e: any) => {
        if (e.value === null && onValueChanged) {
            onValueChanged(e.value);
            return;
        }
        const item = findStartsWithItemByField(e.value);
        if (onValueChanged) onValueChanged(e.value);
        if (!item) toast.warning("Por favor informe um valor válido.");
    };

    const handleOnFocusOut = (e: any) => {
        if (!inputValue) return;
        const item = findEqualsItemByField(inputValue);
        if (!item && onValueChanged) {
            onValueChanged('');
            toast.warning("Por favor informe um valor válido.");
        }
    };

    return (
        <Autocomplete
            value={inputValue}
            dataSource={itemsAvailable}
            onValueChanged={handleValueChange}
            label={label}
            placeholder={placeholder}
            width={width}
            height={height}
            labelMode={labelMode}
            mask={mask}
            stylingMode={stylingMode}
            maxLength={maxLength}
            valueExpr={valueExpr as string}  // Casting para string (Autocomplete exige string)
            searchExpr={searchExpr}
            searchMode={searchMode}
            onFocusOut={handleOnFocusOut}
            onValueChange={onValueChange}
        />
    );
};

export default AutoCompleteCustom;