export const UF = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

export const Genre = ["Feminino", "Masculino"];

export const Years = ["2022 / 2023"];

export const YesNot = ["0 - Não", "1 - Sim"];

export const Satisfacao = ["1 - Satisfeito", "2 - Não Satisfeito"];

export const Rusticidade = ["1 - Boa", "2 - Mediana", "3 - Ruim"];

export const BoMeRu = ["1 - Boa", "2 - Mediana", "3 - Ruim"];

export const DistribuicaoSementes = [
  "1 - Pneumática",
  "2 - Mecânica",
  "3 - Outro",
];

export const OndeRealizou = ["1 - TSI", "2 - On Farm", "3 - Não realizou"];

export const Sistema = ["1 - Plantio Direto", "2 - Convencional"];

export const CategorieSubscribe = ["1 - Sequeiro", "2 - Irrigado"];

export const SystemProduction = [
  "1 - Plantio Direto",
  "2 - Convencional",
  "3 - Outro",
];

export const InfoHibrido = `
    Caso você tenha utilizado mais de um hibrido na propriedade clique no botão de “ + “ e adicione os dados dele também. O objetivo desse campo é adicionar todos os híbridos utilizados na propriedade, mesmo que o hibrido não seja o utilizado no talhão que irá competir.
`;

export const HelpCalagem = `
    Caso você não realize a calagem do solo, colocar 0 na frequência.
`;
export const HelpGessagem = `
    Caso você não realize a gessagem, colocar 0 na frequência.
`;
export const HelpDescompactacao = `
    Caso você não realize a descompactacao, colocar 0 na frequência.
`;

export const HelpCultura = `
Caso você não tenha o conhecimento de qual cultura foi utilizada nas safras anteriores coloque 0. Para plantio de cobertura adicione qual a cultura utilizada no plantio de cobertura.
`;

export const HelpDataCalagem = `
    Colocar a data da ultima realização da calagem do solo.
`;
export const HelpDataGessagem = `
    Colocar a data da ultima realização da gessagem.
`;
export const HelpDataDescompactacao = `
    Colocar a data da ultima realização da descompactação.
`;

export const HelpTsiOnFarm = `
    TSI: Tratamento de Sementes industrial,
    On farm: Tratamento realizado na própria fazenda.
`;

export const HelpAdubacaoPreSeme = `
    Caso você tenha feito mais de um tipo de adubação de pré-semeadura, clique no botão de "+" para adicionar.
`;
export const HelpAdubacaoSeme = `
    Caso você tenha feito mais de um tipo de adubação de semeadura, clique no botão de "+" para adicionar.
`;
export const HelpAdubacaoCobertura = `
    Caso você tenha feito mais de um tipo de adubação de cobertura, clique no botão de "+" para adicionar.
`;
export const HelpAdubacaoFoliar = `
    Caso você tenha feito mais de um tipo de adubação de foliar, clique no botão de "+" para adicionar.
`;
export const HelpAdubacaoFisioVegetal = `
    Caso você tenha feito mais de um tipo de especialidades ou fisiologia, clique no botão de "+" para adicionar.
`;

export const HelpHerbicida = `
    Caso você tenha feito outra aplicação de herbicida, com o mesmo ou outros produtos, clique no "+" para adicionar.
`;
export const HelpInseticidaLagarta = `
    Caso você tenha feito outra aplicação de inseticida lagartas, com o mesmo ou outros produtos, clique no "+" para adicionar.
`;
export const HelpInseticidaCigarrinha = `
    Caso você tenha feito outra aplicação de inseticida cigarrinha, com o mesmo ou outros produtos, clique no "+" para adicionar.
`;
export const HelpFungicida = `
    Caso você tenha feito outra aplicação de fungicida, com o mesmo ou outros produtos, clique no "+" para adicionar.
`;
export const HelpNematicida = `
    Caso você tenha feito outra aplicação de biodefensivo, com o mesmo ou outros produtos, clique no "+" para adicionar.
`;

export const HelpAgendamentoAutiroria = `
    Nessa etapa é necessário deixar um responsável para acompanhar e agendar a auditoria.
    O responsável para confirmar a data de previsão de colheita.
    Alguns dias antes da data agendada o auditor irá entrar em contato novamente para aconfirmar se não teve alteração da data.
`;

export const HelpDescompacSolo = `
    Caso você não realize descompactação do solo, colocar 0 na frequência.
`;

export const propertie = ["Fez", "Não fez"];

export const methodPayment = ["pix", "creditCard"];

export const NivelUtilizacao = ["1 - Muito", "2 - Mediano", "3 - Pouco"];

export const DataStepTeen = [
  {
    formula: "Formula01",
    dose: "Uma dose",
    nome_comercial: "Nome do comarcial",
    fabricante: "Fabric",
    aplic: "Essa é a aplicação",
    invest_adub_pre_seme: "sim",
    micronutrientes: "Um micronutrientes",
    nome_comerci_nutri: "O nome do micronutriente",
    invest_micro_nutri: "Sim",
  },
  {
    formula: "Formula01",
    dose: "Uma dose",
    nome_comercial: "Nome do comarcial",
    fabricante: "Fabric",
    aplic: "Essa é a aplicação",
    invest_adub_pre_seme: "sim",
    micronutrientes: "Um micronutrientes",
    nome_comerci_nutri: "O nome do micronutriente",
    invest_micro_nutri: "Sim",
  },
  {
    formula: "Formula01",
    dose: "Uma dose",
    nome_comercial: "Nome do comarcial",
    fabricante: "Fabric",
    aplic: "Essa é a aplicação",
    invest_adub_pre_seme: "sim",
    micronutrientes: "Um micronutrientes",
    nome_comerci_nutri: "O nome do micronutriente",
    invest_micro_nutri: "Sim",
  },
  {
    formula: "Formula01",
    dose: "Uma dose",
    nome_comercial: "Nome do comarcial",
    fabricante: "Fabric",
    aplic: "Essa é a aplicação",
    invest_adub_pre_seme: "sim",
    micronutrientes: "Um micronutrientes",
    nome_comerci_nutri: "O nome do micronutriente",
    invest_micro_nutri: "Sim",
  },
];

export const DataEstadioFenologico = [
  "V1",
  "V2",
  "V3",
  "V4",
  "V5",
  "V6",
  "V7",
  "V8",
  "V9",
  "V10",
  "V11",
  "V12",
  "Pré Pendoamento",
  "R1",
  "R2",
  "R3",
  "R4",
  "R5",
  "R6",
  "R7",
  "R8",
  "R9",
  "R10",
  "R11",
  "R12",
  "Outros",
];

export const PrePendoamento = [
  "R1",
  "R2",
  "R3",
  "R4",
  "R5",
  "R6",
  "R7",
  "R8",
  "R9",
  "R10",
  "R11",
  "R12",
];

export const UnidadeMedida = [
  "Kg/ha",
   "T/ha"
]

export const UnidadeMedidaFormulaAdubo = [
  "Kg/ha",
   "T/ha",
   "g/ha",
   "l/ha",
   "ml/ha"
]

export const producer_company_type = ["Produtor", "Empresa"];

export const outros_nutrientes = ["Ca", "Mg", "S", "Fe", "Mn", "Zn", "Cu", "B", "Mo"]