import { createContext, ReactNode, useContext, useReducer } from 'react';

type State = {
    currentStep: number;
    nome: string;
    email: string;
    dtNascimento: string;
    genero: string;
    foneOne: string;
    foneTwo: string;
    cep: string;
    endereco: string;
    bairro: string;
    cidade: string;
    uf: string;
    senha: string;
}
type Action = {
    type: FormActions;
    payload: any;
}
type ContextType = {
    state: State;
    dispatch: (action: Action) => void;
}
type FormProviderProps = {
    children: ReactNode;
}

const initialData: State = {
    currentStep: 1,
    nome: 'Leonardo',
    email: '',
    dtNascimento: '',
    genero: '',
    foneOne: '',
    foneTwo: '',
    cep: '',
    endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    senha: ''
}

const FormContext = createContext<ContextType | undefined>(undefined);

export enum FormActions {
    setCurrentStep,
    setEmail,
    setNome,
    setDtNascimento,
    setGenero,
    setFoneOne,
    setFoneTwo,
    setCep,
    setEndereco,
    setBairro,
    setCidade,
    setUf,
    setSenha
}

const formReducer = (state: State, action: Action) => {
    switch (action.type) {
        case FormActions.setCurrentStep:
            return {...state, currentStep: action.payload};
        case FormActions.setNome:
            return {...state, nome: action.payload};
        case FormActions.setEmail:
            return {...state, email: action.payload};
        case FormActions.setDtNascimento:
            return {...state, dtNascimento: action.payload};
        case FormActions.setGenero:
            return {...state, genero: action.payload};
        case FormActions.setFoneOne:
            return {...state, foneOne: action.payload};
        case FormActions.setFoneTwo:
            return {...state, foneTwo: action.payload};
        case FormActions.setCep:
            return {...state, cep: action.payload};
        case FormActions.setEndereco:
            return {...state, endereco: action.payload};
        case FormActions.setBairro:
            return {...state, bairro: action.payload};
        case FormActions.setCidade:
            return {...state, cidade: action.payload};
        case FormActions.setUf:
            return {...state, uf: action.payload};
        case FormActions.setSenha:
            return {...state, senha: action.payload};
        default:
            return state;
    }
}

export const FormProvider = ({children}: FormProviderProps) => {
    const [state, dispatch] = useReducer(formReducer, initialData);
    const value = { state, dispatch };

    return (
        <FormContext.Provider value={value}>
            {children}
        </FormContext.Provider>
    );
}

export const useForm = () => {
    const context = useContext(FormContext);
    if(context === undefined) {
        throw new Error('useForm precisa ser usado dentro do FormProvider');
    }
    return context;
}

