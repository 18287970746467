import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import 'devextreme/dist/css/dx.light.css';
import { CheckBox, SelectBox, TextBox } from 'devextreme-react';
import { HelpAgendamentoAutiroria, UF } from '../../data/data';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { Tooltip } from '../../components/Tooltip';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { Input } from '../../components/input/Input';
import { maskDate } from '../../helpers/helperDate';
import { useAppStore } from '../../store';
import { IProducer } from '../../store/types';
// import { revertDateMonthYear } from '../../helpers/helperDate';

export const FormStep12 = () => {
  const subscription = useAppStore((store) => store.subscription);
  const clearSubscription = useAppStore((store) => store.clearSubscription);

  const navigate = useNavigate();
  const [previsao_colheita, setPrevCol] = useState('');
  const [ac_auditoria_name, setAcName] = useState('');
  const [ac_auditoria_contato1, setAcCont1] = useState('');
  const [ac_auditoria_contato2, setAcCont2] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [producerCompany, setProducerCompany] = useState({} as IProducer);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const findProducerCompanyByDocument = () => {
    console.log(`subscription.resp = ${JSON.stringify(subscription.resp)}`);
    api
      .get(
        `/subscription/producer-company/${
          subscription.resp?.cpf_cnpj_prod === ''
            ? subscription.resp?.cnpj_empresa
            : subscription.resp?.cpf_cnpj_prod
        }`
      )
      .then((res) => {
        console.log(res.data);
        setProducerCompany(res.data);
        // setProducerCompany(res.data.nome);
        // setCidadeExisting(res.data.cidade);
        // return toast.info(`O CPF/CNPJ informado já está cadastrado para [ ${res.data.nome} ]`);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.info('Falha ao buscar o CPF/CNPJ.');
      });
  };

  useEffect(() => {
    console.log(`producerCompany.nome = ${producerCompany.nome}`);
    if (producerCompany.nome === undefined) {
      findProducerCompanyByDocument();
    }
  }, [subscription, findProducerCompanyByDocument, producerCompany.nome]);

  const handleSubmit = useCallback(() => {
    if (previsao_colheita === '') {
      return toast.error('Previsão de colheita obrigatória!');
    }

    if (ac_auditoria_name === '') {
      return toast.error('Nome do acompanhante obrigatório!');
    }

    if (ac_auditoria_contato1 === '') {
      return toast.error('Informe ao menos um telefone para contato!');
    }

    if (!confirm) {
      return toast.error('Confirme as informações');
    }

    // Tratando data pra enviar pro banco no formato 0000-00-00
    // Toda data enviar com o dia 00 é de input mes/ano
    const nd = maskDate(previsao_colheita).split('/');

    api
      .post('/subscription/appointment', {
        subscription_id: subscription.id,
        previsao_colheita: `${nd[2]}-${nd[1]}-${nd[0]}`,
        ac_auditoria_name,
        ac_auditoria_contato1,
        ac_auditoria_contato2,
        step: 12,
      })
      .then((res) => {
        if (res.status === 201) {
          clearSubscription();
          toast.success('Salvo com sucesso!');
          navigate('/concluido');
        }
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`);
      });
  }, [
    previsao_colheita,
    ac_auditoria_name,
    ac_auditoria_contato1,
    ac_auditoria_contato2,
    confirm,
    navigate,
    clearSubscription,
    subscription,
  ]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <div
            style={{
              position: 'absolute',
              left: '300px',
              zIndex: '1000',
              color: '#2F80ED',
            }}
          >
            <Tooltip
              info={HelpAgendamentoAutiroria}
              icon={<AiFillQuestionCircle />}
            />
          </div>
          <Title title='Previsão de colheita' />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>
        <br />
        <C.ContentRightForm>
          <C.BoxFields>
            <C.ContentRightFormBoxLeft>
              <C.InfoDestaque>
                <Input
                  width='60%'
                  style={{ marginBottom: '15px' }}
                  label='Data prevista de colheita na área inscrita (dia/mês/ano) (*)'
                  type='text'
                  value={maskDate(previsao_colheita)}
                  maxLength={10}
                  onChange={(e) => setPrevCol(e.target.value)}
                />
                {/* <DateBox
                                    value={previsao_colheita}
                                    onValueChanged={(e) => setPrevCol(e.value)}
                                    stylingMode='underlined'
                                    labelMode='static'
                                    type='date'
                                    //mask='00/0000'
                                    displayFormat="MM/yyyy"
                                    label='Período de realização (mês/ano)'
                                    width="50%" height={50}
                                /> */}
              </C.InfoDestaque>

              <Title title='Contato de quem acompanhará auditoria' />

              <C.GroupInputs>
                <TextBox
                  value={ac_auditoria_name}
                  onValueChange={(e) => setAcName(e)}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Nome (*)'
                  width='100%'
                  height={50}
                  style={{ marginRight: '20px' }}
                />
              </C.GroupInputs>
              <C.GroupInputs>
                <C.BoxInput>
                  <TextBox
                    value={ac_auditoria_contato1}
                    onValueChange={(e) => setAcCont1(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Telefone / WhatsApp (opção 01) (*)'
                    mask='(00) 0 0000-0000'
                    width='100%'
                    height={50}
                    style={{ marginRight: '20px' }}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  <TextBox
                    value={ac_auditoria_contato2}
                    onValueChange={(e) => setAcCont2(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Telefone / WhatsApp (opção 02)'
                    mask='(00) 0 0000-0000'
                    width='100%'
                    height={50}
                    style={{ marginRight: '20px' }}
                  />
                </C.BoxInput>
              </C.GroupInputs>

              <C.Label>Confirme os dados da inscrição:</C.Label>

              <TextBox
                readOnly
                value={producerCompany?.nome}
                // value={subscription.resp?.producer_company?.nome_resp}
                stylingMode='underlined'
                labelMode='static'
                label='Nome do produtor'
                width='100%'
                height={50}
                placeholder='José Bonifácio da Silva'
                style={{ marginRight: '20px', color: '#444' }}
              />
              <C.GroupInputs>
                <C.BoxInput>
                  <TextBox
                    readOnly
                    value={subscription.property?.cidade}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Cidade'
                    width='100%'
                    height={50}
                    placeholder='Uberlândia'
                    style={{ marginRight: '20px', color: '#444' }}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  <SelectBox
                    readOnly
                    items={UF}
                    value={subscription.property?.estado}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Estado'
                    width='100%'
                    height={50}
                    style={{ marginRight: '20px', color: '#444' }}
                  />
                </C.BoxInput>
              </C.GroupInputs>
              <TextBox
                readOnly
                value={subscription.property?.fazenda}
                stylingMode='underlined'
                labelMode='static'
                label='Nome da propriedade'
                width='100%'
                height={50}
                placeholder='Fazenda Santa Clara'
                style={{ marginRight: '20px', color: '#444' }}
              />
              <TextBox
                readOnly
                value={subscription.history?.nome_talha}
                stylingMode='underlined'
                labelMode='static'
                label='Talhão inscrito'
                width='100%'
                height={50}
                placeholder='Fazenda Santa Clara'
                style={{ marginRight: '20px', color: '#444' }}
              />
              <TextBox
                readOnly
                value={subscription.history?.category}
                stylingMode='underlined'
                labelMode='static'
                label='Categoria'
                width='100%'
                height={50}
                placeholder='Fazenda Santa Clara'
                style={{ marginRight: '20px', color: '#444' }}
              />
              <TextBox
                readOnly
                value={subscription.history?.hibrido}
                stylingMode='underlined'
                labelMode='static'
                label='Hibrido inscrito'
                width='100%'
                height={50}
                placeholder='Fazenda Santa Clara'
                style={{ marginRight: '20px', color: '#444' }}
              />

              <C.WrapperCheckbox>
                <CheckBox
                  value={confirm}
                  onValueChange={(e) => setConfirm(e)}
                  text='Confirmo que os dados da propriedade estão corretos'
                />
              </C.WrapperCheckbox>
            </C.ContentRightFormBoxLeft>
          </C.BoxFields>

          <C.BoxButtonSubmit>
            <Button
              onClick={() => navigate('/passo11')}
              title='VOLTAR'
              color='#2F80ED'
              iconLeft={<BiChevronLeft fontSize={22} />}
            />
            <Button
              onClick={handleSubmit}
              title='SALVAR E FINALIZAR'
              color='#27AE60'
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </C.BoxButtonSubmit>
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
