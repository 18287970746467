import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;

  h2 {
    color: #333;
  }

  .button {
    margin-top: 48px;
    width: 33%;
    padding: 8px 16px;
    background-color: #27ae60;
    border: none;
  }
`;

export const BoxDataTable = styled.div`
  max-width: 760px;
  margin-top: 25px;
`;

export const DataSubscription = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  div {
    display: flex;
    flex-direction: column;
    border-bottom: 1px dotted #ccc;
    padding: 8px 0;

    label {
      font-size: 1rem;
      color: #888;
    }

    span {
      color: #222;
      padding-top: 8px;
    }
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
