import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: 100vh;
    margin-bottom: 40px;
    
    #form-checkout {
      display: flex;
      flex-direction: column;
    }

    #form-checkout2 {
      display: flex;
      flex-direction: column;
    }

    .container {
      height: 18px;
      display: inline-block;
      border: 1px solid rgb(118, 118, 118);
      border-radius: 2px;
      padding: 1px 2px;
    }
`;

export const ContentLeft = styled.div`
    width: 25%;
    padding: 20px;
    height: 100%;
    background-color: #F0F0F0;
    border-radius: 6px;
`;

export const BoxInputDate = styled.div`
    margin:20px 0;
    color: #444;

    input[type="date"] {
        width: 100%;
        margin-top: 10px;
        color: inherit;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #CCC;
        background-color: #F0F0F0;
        outline: 0;
    }
`;

export const ContentRight = styled.div`
    width: 100%;
    max-width: 1000px;
    padding: 20px 40px;

    @media screen and (max-width:760px){
        padding: 20px 0;
    }
`;

export const HeaderContentRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
        color: #777;
        font-size:12px;
    }

    @media screen and (max-width: 760px) {
        flex-direction: column;
        margin-bottom:10px;
    }
`;

export const ContentRightForm = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
`;

export const BoxInfoMercadoPago = styled.div`
    display: flex;
    justify-content: space-between;
    width:100%;
    margin:10px 0;
    color: #4F4F4F;
    padding:0 15px 15px 15px;
    border-bottom: 1px solid #919191;
    
    img {
        width: 119px;
        height:31px;
        margin-right: 20px;
    }
`;

export const ContentRightFormBoxLeft = styled.div`
    flex: 1;
    padding: 0 20px 0 0;

    @media screen and (max-width: 760px){
        padding: 0;
    }
`;
export const ContentRightFormBoxRight = styled.div`
    flex: 1;
    padding: 0 0 0 20px;
`;

export const BoxButtonSubmit = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    margin-top: 10px;

    button {
        width: 193px;
        @media screen and (max-width:760px){
            width: 100%;
            margin-bottom:0;
        }
    }

    @media screen and (max-width:760px){
        flex-direction: column;
        margin-bottom: 40px;
    }
`;

export const GroupInputs = styled.div`
    position:relative;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width:100%;
    margin:10px 0;

    button {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 150px;
        height: 42px;

        font-size: 15px;
        font-weight: 500;
        color: #FFF; 

        text-decoration: none;
        padding:10px;
        margin-top:10px;
        margin-bottom: 40px;
        
        border: 0;
        cursor: pointer;
        border-radius: 4px;
        box-shadow: 1px 2px 8px rgba(0,0,0,.3);
        background-color: #27AE60;
        transition: all ease .3s;
        
        &:hover {
            opacity: .8;
        }
        
        @media screen and (max-width:500px){
            font-size: 12px;
            margin-bottom: 0;
        }
    }

    img {
        position:absolute;
        right:0;
        bottom:10px;
        width: 52.18px;
        height: 33.15px;        
    }
`;

export const BoxInput = styled.div`
    width: 100%;
    border-right: 20px solid #fff;
`;

export const BoxDataCard = styled.div`
    width:100%;
    height: auto;
    border-radius:4px;
    padding: 15px;
    border: 1px solid #f1f2f3;
    margin-bottom: 15px;
    box-shadow: 0 2px 5px rgba(0,0,0, .1);

    #form-checkout {
        gap: .8rem;
    }

    #form-checkout label {
        color: #444;
        font-size: .9rem;
    }

    #form-checkout div, input, select {
        height: 34px;
        border: 0;
        border-bottom: 1px solid #ccc;
        outline: none;
    }

    #form-checkout button {
        height: 34px;
        font-size: 15px;
        font-weight: 500;
        color: #FFF; 
        background-color: #27AE60;
        border: 0;
        cursor: pointer;
        border-radius: 4px;
        box-shadow: 1px 2px 8px rgba(0,0,0,.3);
    }

    #form-checkout button:hover {
        opacity: .8;
    }

    #form-checkout2 {
        gap: .8rem;
    }

    #form-checkout2 label {
        color: #444;
        font-size: .9rem;
    }

    #form-checkout2 div, input, select {
        height: 34px;
        border: 0;
        border-bottom: 1px solid #ccc;
        outline: none;
    }

    #form-checkout2 button {
        height: 34px;
        font-size: 15px;
        font-weight: 500;
        color: #FFF; 
        background-color: #27AE60;
        border: 0;
        cursor: pointer;
        border-radius: 4px;
        box-shadow: 1px 2px 8px rgba(0,0,0,.3);
    }

    #form-checkout2 button:hover {
        opacity: .8;
    }
`;

export const BoxHeader = styled.header`
    display: flex;
    flex-direction:row-reverse;
    justify-content: flex-end;
    align-items: center;

    label {
        font-size: 1.2rem;
        color: #4f4f4f;
        margin: 0 8px;
    }
`;

export const LabelInfo = styled.div`
    font-size: 1rem;
    color: #2F80ED;
    margin-top: 18px;
`;

export const BoxFooterDataCard = styled.div`
    display: flex;
    justify-content: center;
    margin: 25px 0 15px 0;
    color: #4F4F4F;
    font-size: 12px;

    label {
        text-decoration: underline;
        color: #2F80ED;
        margin-left: 5px;
    }
`;

export const BoxInfoDataSelf = styled.div`
    text-align: center;
    color: #4F4F4F;
    font-size:12px;
    padding: 20px 10px;

    label {
        text-decoration: underline;
        color: #2F80ED;
    }
`;

export const ContentQRC = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    img {
        width: 320px;
        height: 320px;
        border: 5px solid #2F80ED;
        border-radius: 8px;
    }

    label {
        margin-top: 30px;
    }

    input {
        width: 80%;
        
    }

    .infoPay{
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;

        span{
            color: #2F80ED;
            font-weight: bold;
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
        }
    }
`;