import { ButtonHTMLAttributes, ReactNode } from 'react';
import * as C from './styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;
    color: string;
    iconRight?: ReactNode;
    iconLeft?: ReactNode;
    path?: string;
}

export const Button = ({ title, color, iconRight, iconLeft, path, ...rest }: Props) => {
    return (
        <C.Button color={color} {...rest}>
            {iconLeft} {title} {iconRight}
        </C.Button>
    )
}