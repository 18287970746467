import { ReactNode } from "react";
import * as C from "./styles";

interface IDataModal {
  id?: number;
  titleHeader: string;
  title?: string;
  desc?: string;
  src?: string;
  value?: number;
  children?: ReactNode;
  elementButton?: ReactNode;
  mxheight?: string;

  onClose: () => void;
}

export const Modal = ({
  titleHeader,
  title,
  desc,
  src,
  children,
  mxheight,
  onClose,
}: IDataModal) => {
  return (
    <C.Container>
      <C.Content mxheight={mxheight}>
        <C.Header>
          <h4>{titleHeader}</h4>
          <span onClick={onClose}></span>
        </C.Header>
        <C.BoxInfo>
          <C.Img src={src}></C.Img>
          <C.InfoProd>
            {children}
            <h4>{title}</h4>
            <div>{desc}</div>
          </C.InfoProd>
          {/* <C.Footer>
                    </C.Footer> */}
        </C.BoxInfo>
      </C.Content>
    </C.Container>
  );
};
