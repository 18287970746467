import { Popup } from 'devextreme-react';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: 100vh;
    margin-bottom: 40px;
`;

export const ContentLeft = styled.div`
    width: 25%;
    padding: 20px;
    height: 100%;
    background-color: #F0F0F0;
    border-radius: 6px;
`;

export const BoxInputDate = styled.div`
    margin:20px 0;
    color: #444;

    input[type="date"] {
        width: 100%;
        margin-top: 10px;
        color: inherit;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #CCC;
        background-color: #F0F0F0;
        outline: 0;
    }
`;

export const ContentRight = styled.div`
    width: 100%;
    max-width: 1000px;
    padding: 20px 40px;

    @media screen and (max-width:760px){
        padding: 20px 0;
    }
`;

export const HeaderContentRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
        color: #777;
        font-size:12px;
    }

    @media screen and (max-width: 760px) {
        flex-direction: column;
        margin-bottom:10px;
    }
`;

export const ContentRightForm = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
`;

export const BoxFields = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ContentRightFormBoxLeft = styled.div`
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px 0 0;

    @media screen and (max-width: 760px){
        padding: 0;
    }
`;
export const ContentRightFormBoxRight = styled.div`
    flex: 1;
    padding: 0 0 0 20px;
`;

export const BoxUpdateDelete = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

export const BoxButtonSubmit = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    margin-top: auto;

    button {
        width: 193px;
        @media screen and (max-width:760px){
            width: 100%;
            margin-bottom:0;
        }
    }

    @media screen and (max-width:760px){
        flex-direction: column;
        margin-bottom: 40px;
    }
`;

export const InfoSection = styled.label`
    width: 100%;
    color: #2F80ED;
    font-size: 15px;
    margin:15px 0;
`;

export const WrapperCheckbox = styled.div`
    width: 100%;
    margin-bottom: 15px;

    div {
        margin-right:10px;
        color: #525252;
    }
`;

export const BoxButton = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* gap: 2rem; */
`;

export const Label = styled.label`
    color: #525252;
    margin: 20px 0;
    width: 100%;
`;

export const BtnAdd = styled.div`
    display: flex;
    align-items: center;
    gap: 0.2rem;
    padding:5px 12px;
    margin-left: auto;
    border-radius: 5px;
    background-color: #ebebeb;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;

export const InfoDestaque = styled.div`
    display:flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 760px) {
        flex-direction: column;
    }
`;

export const GroupInputs = styled.div`
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap:1rem;

    @media screen and (max-width: 760px) {
        grid-template-columns: 1fr;
    }
`;

export const BoxInput = styled.div`
    width: 100%;
    border-right: 10px solid #fff;
`;

export const Separator = styled.div`
    width: 100%;
    margin-top: 10px;
    border-bottom:1px solid #ebeded;
`;

export const MyPopup = styled(Popup).attrs({
    maxHeight: '400px',
    style:{
        '@media screen and (max-width:760px)':{
            maxHeight: '800px'
        }
    }
})`
    max-width: 800px;    
`;