import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  margin-bottom: 40px;
`;

export const ContentLeft = styled.div`
  width: 25%;
  padding: 20px;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 6px;
`;

export const BoxInputDate = styled.div`
  margin: 20px 0;
  color: #444;

  input[type="date"] {
    width: 100%;
    margin-top: 10px;
    color: inherit;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #ccc;
    background-color: #f0f0f0;
    outline: 0;
  }
`;

export const ContentRight = styled.div`
  width: 100%;
  max-width: 1000px;
  padding: 20px 40px;

  @media screen and (max-width: 760px) {
    padding: 20px 0;
  }
`;

export const GroupInputsEpecified = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin: 10px 0;

  /* div {
        margin-right:10px;
    } */

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;

export const WrapperCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;

  input[type="checkbox"] {
    min-width: 16px;
    min-height: 16px;
    margin-right: 8px;
    cursor: pointer;
  }

  span {
    font-size: 15px;
    color: #4f4f4f;
  }
`;

export const HeaderContentRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    color: #777;
    font-size: 12px;
  }

  @media screen and (max-width: 760px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

export const ContentRightForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
`;

export const BoxFields = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentRightFormBoxLeft = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px 0 0;

  @media screen and (max-width: 760px) {
    padding: 0;
  }
`;
export const ContentRightFormBoxRight = styled.div`
  flex: 1;
  padding: 0 0 0 20px;
`;

export const BoxButtonSubmit = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  margin-top: 10px;

  button {
    width: 193px;
    @media screen and (max-width: 760px) {
      width: 100%;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 760px) {
    flex-direction: column;
    margin-bottom: 40px;
  }
`;

export const InfoSection = styled.label`
  width: 100%;
  color: #2f80ed;
  font-size: 15px;
  margin: 15px 0;
`;

export const GroupInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin: 0 0 15px 0;

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

export const GroupInputs2 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  margin: 0 0 15px 0;

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

export const BoxInput = styled.div`
  position: relative;
  width: 100%;
  border-right: 10px solid #fff;
`;

export const LabelInfo = styled.div`
    font-size: 1rem;
    color: #2F80ED;
    margin-top: 18px;
`;

export const BoxInputWithBtnPlus = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    border-right: 10px solid #fff;

    span {
        cursor: pointer;
    }
`;