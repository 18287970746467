import { create } from "zustand";
import api from "../services/api";
import { IConfig, IListItem, ISubscription, User } from "./types";

type AppDataStore = {
  subscription: ISubscription;
  subscriptions: ISubscription[];
  user: User;
  isLoading: boolean;
  config: IConfig;
  listNutriCultive: IListItem[];
  itemListSelected: IListItem;

  setItemListSelected: (item: IListItem) => void;
  loadListNutriCultive: (id: number) => Promise<void>;
  setUser: (user: User) => void;
  setSubscription: (subscription: ISubscription) => void;
  setStep: (step: number) => void;
  setTerm: (term: boolean) => void;

  loadSubscriptions: () => Promise<void>;
  loadSubscription: (id: number) => Promise<void>;
  loadConfig: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;

  updateSubscription: (subscription: ISubscription) => void;
  clearSubscription: () => void;

  clearItemList: () => void;
  notAllowedNewSubscription: boolean;
};

export const useAppStore = create<AppDataStore>((set, get) => {
  return {
    subscription: {
      id: 0,
      user_id: 0,
      safra: "",
      accept_term: 0,
      cupom_id: 0,
      is_cupom: 0,
      status: 0,
      step: 0,
      payment_id: null,
      resp_id: 0,
      property_id: 0,
      property_info_id: 0,
      history_id: 0,
      area_info_id: 0,
      culture_info_id: 0,
      nutrition_id: 0,
      cultivo_id: 0,
      created_at: new Date("2000-01-01"),
      resp: null,
      property: null,
      property_info: null,
      history: null,
      payment: null,
      cupom: null,
      culture_info: null,
      nutrition: null,
      cultivo: null,
      area_info: null,
    },
    listNutriCultive: [],
    itemListSelected: {} as IListItem,
    subscriptions: [],
    notAllowedNewSubscription: false,
    user: {} as User,
    isLoading: true,
    config: {
      configSub: true,
      configPix: true,
      configCard: true,
      configCupom: true,
      safra: "2023/Inverno",
    },

    setUser: (user: User) => {
      set({ user });
    },

    setTerm: (term: boolean) => {
      const { subscription } = get();
      set({
        subscription: {
          ...subscription,
          accept_term: term ? 1 : 0,
        },
      });
    },

    setStep: (step: number) => {
      const { subscription } = get();
      set({
        subscription: {
          ...subscription,
          step,
        },
      });
    },

    clearSubscription: () => {
      localStorage.removeItem("@GetapAPP:subscription_id");
      set({
        subscription: {
          id: 0,
          user_id: 0,
          safra: "",
          accept_term: 0,
          cupom_id: 0,
          is_cupom: 0,
          status: 0,
          step: 0,
          payment_id: null,
          resp_id: 0,
          property_id: 0,
          property_info_id: 0,
          history_id: 0,
          area_info_id: 0,
          culture_info_id: 0,
          nutrition_id: 0,
          cultivo_id: 0,
          created_at: new Date("2000-01-01"),
          resp: null,
          property: null,
          property_info: null,
          history: null,
          payment: null,
          cupom: null,
          culture_info: null,
          nutrition: null,
          cultivo: null,
          area_info: null,
        },
      });
    },

    setSubscription: (subscription: ISubscription) => {
      localStorage.setItem("@GetapAPP:subscription_id", `${subscription.id}`);
      set({ subscription });
    },

    setIsLoading: (isLoading: boolean) => {
      set({ isLoading });
    },

    loadSubscriptions: async () => {
      set({ isLoading: true });
      const res = await api.get("/subscription/");

      set({
        subscriptions: res.data,
        isLoading: false,
        notAllowedNewSubscription: res.data.some(
          (eachStatus: ISubscription) => eachStatus.status === 0
        ),
      });
    },

    loadConfig: async () => {
      set({ isLoading: true });
      const res = await api.get("/sistem/config");
      set({
        config: {
          configSub: res.data.subscription === 1,
          configPix: res.data.pix === 1,
          configCard: res.data.card === 1,
          configCupom: res.data.cupom === 1,
          safra: res.data.safra,
        },
      });
    },

    loadSubscription: async (id) => {
      set({ isLoading: true });
      const res = await api.get(`/subscription/${id}`);

      set({
        subscription: res.data,
        isLoading: false,
      });
    },

    updateSubscription: (subscription: ISubscription) => {
      set({ subscription });
    },

    loadListNutriCultive: async (id: number) => {
      set({ isLoading: true });
      const res = await api.get(`/subscription/list/${id}`);

      set({
        listNutriCultive: res.data,
        isLoading: false,
      });
    },

    setItemListSelected: (item: IListItem) => {
      set({
        itemListSelected: item,
      });
    },

    clearItemList: () => {
      set({
        itemListSelected: {} as IListItem,
      });
    },
  };
});
