import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import 'devextreme/dist/css/dx.light.css';
import { SelectBox, TextBox } from 'devextreme-react';
import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppStore } from '../../store';
import { UF } from '../../data/data';
import municipiosData from '../../data/municipios.json';
import { cpf } from 'cpf-cnpj-validator';
import {
  removeSpacesAndSpecialChars,
  validateEmail,
} from '../../utils/StringUtils';
import { Validator, EmailRule } from 'devextreme-react/validator';
import AutoCompleteCustom, {
  IMunicipio,
} from '../../components/AutoCompleteCustom';

export const FormStep2 = () => {
  const navigate = useNavigate();
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const isLoading = useAppStore((store) => store.isLoading);

  const [nome_responsavel_sub, setNomeResponsavelSub] = useState(
    subscription.resp?.nome_resp
  );
  const [phone_cons, setContatoCons] = useState(subscription.resp?.phone_resp);
  const [email_cons, setEmailCons] = useState(subscription.resp?.email_resp);

  const [cpf_cnpj_prod, setCpfCnpjProd] = useState(
    subscription.resp?.cpf_cnpj_prod
  );
  const [nome_cons, setNomeCons] = useState(subscription.resp?.nome_consultor);

  const [cpf_cnpj_consultor, setCpfCnpjConsultor] = useState(
    subscription.resp?.cpf_cnpj_consultor
  );
  const [municipio_consultor, setMunicipioConsultor] = useState(
    subscription.resp?.municipio_consultor
  );
  const [uf_consultor, setUfConsultor] = useState(
    subscription.resp?.uf_consultor
  );
  const [municipiosPopup, setMunicipiosPopup] = useState(municipiosData);

  //fields popup
  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);
  const config = useAppStore((store) => store.config);
  const subscription_id = localStorage.getItem('@GetapAPP:subscription_id');

  const updateDatasource = (e: string) => {
    setMunicipiosPopup(municipiosData.filter((i) => i['UF'] === e));
  };

  useEffect(() => {
    if (
      subscription_id !== null &&
      Number(subscription_id) !== subscription.id
    ) {
      loadSubscription(Number(subscription_id));
    }
    setNomeResponsavelSub(subscription.resp?.nome_resp);
    setNomeCons(subscription.resp?.nome_consultor);
    setContatoCons(subscription.resp?.phone_resp);
    setEmailCons(subscription.resp?.email_resp);
    setCpfCnpjProd(subscription.resp?.cpf_cnpj_prod);
    setNomeCons(subscription.resp?.nome_consultor);
    setCpfCnpjConsultor(subscription.resp?.cpf_cnpj_consultor);
    setMunicipioConsultor(subscription.resp?.municipio_consultor);
    setUfConsultor(subscription.resp?.uf_consultor);
  }, [subscription_id, subscription, loadSubscription]);

  const handleSave = useCallback(async () => {
    if (
      nome_responsavel_sub === '' ||
      nome_cons === '' ||
      cpf_cnpj_consultor === '' ||
      email_cons === '' ||
      phone_cons === '' ||
      uf_consultor === '' ||
      municipio_consultor === ''
    ) {
      return toast.error('Atenção! preencha todos os campos.');
    }

    if (!validateEmail(email_cons)) {
      return toast.error('Atenção! Preencha um email válido.');
    }

    const cpfPure = removeSpacesAndSpecialChars(cpf_cnpj_consultor!);

    if (!cpf.isValid(cpfPure || '')) {
      return toast.error('Preencha um CPF do RTV válido!');
    }

    api
      .post('/subscription/initial', {
        subscription_id: subscription.id !== undefined ? subscription.id : null,
        nome_resp: nome_responsavel_sub,
        phone_resp: phone_cons,
        email_resp: email_cons,
        cpf_cnpj_prod: cpf_cnpj_prod,
        nome_consultor: nome_cons,
        cpf_cnpj_consultor: cpfPure,
        municipio_consultor: municipio_consultor,
        uf_consultor: uf_consultor,
        safra: config.safra,
        step: 2,
      })
      .then((res) => {
        if (res.status === 201) {
          setSubscription(res.data);
          setStep(2);
          toast.success('Salvo com sucesso');
          navigate('/passo3');
        }
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`);
      });
  }, [
    nome_responsavel_sub,
    nome_cons,
    phone_cons,
    cpf_cnpj_prod,
    email_cons,
    cpf_cnpj_consultor,
    municipio_consultor,
    uf_consultor,
    navigate,
    subscription,
    setSubscription,
    config,
    setStep,
  ]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Dados pessoais e de propriedade' />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>
        {isLoading && (
          <C.ContentRightForm>
            <div
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Title title='Carregando...' />
            </div>
          </C.ContentRightForm>
        )}

        {!isLoading && (
          <C.ContentRightForm>
            <C.BoxFields>
              <C.ContentRightFormBoxLeft>
                <TextBox
                  value={config.safra}
                  stylingMode='underlined'
                  labelMode='static'
                  disabled
                  label='Safra (*)'
                  height={50}
                />
                <br />
                <TextBox
                  value={nome_responsavel_sub}
                  onValueChange={(e) => setNomeResponsavelSub(e)}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Nome completo de quem está fazendo a inscrição (*)'
                  height={50}
                />
                <br />
                <TextBox
                  value={nome_cons}
                  onValueChange={(e) => setNomeCons(e)}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Nome do RTV (*)'
                  height={50}
                />
                <br />
                <TextBox
                  value={cpf_cnpj_consultor}
                  onValueChange={(e) => setCpfCnpjConsultor(e)}
                  stylingMode='underlined'
                  labelMode='static'
                  label='CPF do RTV (*)'
                  height={50}
                  maxLength={13}
                  mask='000.000.000-00'
                />
                <br />
                <C.GroupInputs>
                  <TextBox
                    value={email_cons}
                    onValueChange={(e) => setEmailCons(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='E-mail do RTV (*)'
                    height={50}
                    width='100%'
                  >
                    <Validator>
                      <EmailRule message='Informe um e-mail  válido!' />
                    </Validator>
                  </TextBox>
                  <br />
                  <TextBox
                    value={phone_cons}
                    onValueChange={(e) => setContatoCons(e)}
                    mask='(00) 0 0000-0000'
                    stylingMode='underlined'
                    labelMode='static'
                    label='Contato do RTV (*)'
                    height={50}
                    width='100%'
                  />
                  <br />
                </C.GroupInputs>
                <C.GroupInputs>
                  <C.BoxInput>
                    <SelectBox
                      items={UF}
                      value={uf_consultor}
                      onValueChange={(e) => {
                        setUfConsultor(e);
                        updateDatasource(e);
                        setMunicipioConsultor('');
                      }}
                      stylingMode='underlined'
                      labelMode='static'
                      label='UF residência do RTV (*)'
                      width='100%'
                      height={50}
                      style={{ marginRight: '20px', color: '#444' }}
                    />
                  </C.BoxInput>
                  <C.BoxInput>
                    {/* <Autocomplete
                        dataSource={municipiosPopup}
                        valueExpr="MUN"
                        searchExpr="MUN"
                        value={municipio_consultor}
                        label="Município residëncia do RTV  (*)"
                        // onValueChanged={(e) => setMunicipioConsultor(e.value)}
                        onValueChanged={handleValueChangeCidade}
                        onFocusOut={handleOnFocusOutCidade}

                        labelMode="static"
                        stylingMode="underlined"
                        style={{ marginRight: "20px", color: "#444" }}
                        height={50}
                      /> */}

                    <AutoCompleteCustom<IMunicipio>
                      itemsAvailable={municipiosPopup}
                      valueExpr='MUN'
                      searchExpr='MUN'
                      inputValue={municipio_consultor}
                      label='Município residëncia do RTV  (*)'
                      labelMode='static'
                      stylingMode='underlined'
                      height={50}
                      searchMode='startswith'
                      typeData='municipio'
                      onValueChanged={(e) => setMunicipioConsultor(e)}
                    />
                  </C.BoxInput>
                </C.GroupInputs>
              </C.ContentRightFormBoxLeft>
            </C.BoxFields>

            <C.BoxButtonSubmit>
              <Button
                type='button'
                onClick={() => {
                  setStep(0);
                  navigate(`/initial`);
                }}
                title='VOLTAR'
                color='#2F80ED'
                iconLeft={<BiChevronLeft fontSize={22} />}
              />
              <Button
                type='button'
                onClick={handleSave}
                title='SALVAR E AVANÇAR'
                color='#27AE60'
                iconRight={<BiChevronRight fontSize={22} />}
              />
            </C.BoxButtonSubmit>
          </C.ContentRightForm>
        )}
      </C.ContentRight>
      {/* )} */}
    </C.Container>
  );
};
