import { format } from "date-fns";
import { DataGrid } from "devextreme-react";
import { Column, Editing, Paging } from "devextreme-react/data-grid";
import { useCallback, useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { Modal } from "../../components/Modal";
import * as C from "./styles";
import { Button } from "../../components/Button/Button";
import { useAppStore } from "../../store";
import { ISubscription } from "../../store/types";
import { useNavigate } from "react-router-dom";

// interface IDataToModal {
//     codeCupom: string;
//     culturas: {};
//     hibridos: {};
//     isCupom: boolean;
//     manejo: any;
//     pagamento: any;
//     propriedade: IDataSubscribe;
// }

export const SeeData = () => {
  const navigate = useNavigate();
  const subscriptions = useAppStore((store) => store.subscriptions);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const loadSubscriptions = useAppStore((store) => store.loadSubscriptions);

  const [isModalVisibel, setIsModalVisible] = useState(false);

  const [dataToModal, setDataToModal] = useState<ISubscription>(
    {} as ISubscription
  );

  useEffect(() => {
    loadSubscriptions();
  }, [loadSubscriptions]);

  const togglePopup = useCallback(
    (data: any) => {
      console.log(data.data);
      setDataToModal(data.data);
      setIsModalVisible(true);
    },
    [setDataToModal]
  );

  const isOpen = dataToModal.status === 0;

  const btnSearch = (data: any) => {
    return (
      <div style={{ cursor: "pointer" }}>
        <BiSearch size={18} onClick={() => togglePopup(data)} />
      </div>
    );
  };

  async function loadMySubscription(id: number, step: number) {
    await loadSubscription(id);

    navigate(`/passo${step + 1}`);
  }

  return (
    <C.Container>
      <h2>Inscrições</h2>

      <C.BoxDataTable>
        <DataGrid
          id="categorias"
          dataSource={subscriptions}
          keyExpr="id"
          showBorders={false}
          showColumnLines={false}
          columnResizingMode="nextColumn"
          allowColumnResizing={true}
          rowAlternationEnabled={true}
        >
          <Editing
            mode="row"
            allowUpdating={false}
            allowDeleting={false}
            allowAdding={false}
            // useIcons={<BiSearch />}
            onChangesChange={(e) => console.log(e)}
          />
          <Paging defaultPageSize={10} />
          <Column caption="Id" dataField="id" width={60} />
          <Column
            caption="Data Incrição"
            dataField="created_at"
            dataType="date"
            format="dd/MM/yyyy"
          />
          {/* <Column caption="Situação" dataField='situation' /> */}
          <Column caption="Nome Fazenda" dataField="property.fazenda" />
          <Column caption="Safra" dataField="safra" />
          <Column caption="#" width={60} cellRender={btnSearch} />
        </DataGrid>
      </C.BoxDataTable>

      {isModalVisibel ? (
        <Modal
          titleHeader="Inscrição"
          onClose={() => setIsModalVisible(false)}
          mxheight="500px"

          // onHiding={() =>togglePopup('Adubação pré-semeadura')}
        >
          <C.DataSubscription>
            <div>
              <label>Id</label>
              <span>{dataToModal?.id}</span>
            </div>
            <div>
              <label>Data</label>
              <span>
                {dataToModal?.created_at !== undefined &&
                  format(new Date(dataToModal?.created_at), "dd/MM/yyyy")}
              </span>
            </div>
            {/* <div>
                            <label>Situação</label>
                            <span>A sutiação é boa</span>
                        </div> */}
            <div>
              <label>Nome</label>
              <span>{dataToModal?.resp?.nome_resp}</span>
            </div>
            <div>
              <label>Safra</label>
              <span>{dataToModal?.safra}</span>
            </div>
            <div>
              <label>Nome da propriedade</label>
              <span>{dataToModal?.property?.fazenda}</span>
            </div>
            <div>
              <label>Talhão inscrito</label>
              <span>{dataToModal?.history?.nome_talha}</span>
            </div>
            <div>
              <label>Híbrido Inscrito</label>
              <span>{dataToModal?.history?.hibrido}</span>
            </div>
          </C.DataSubscription>
          {dataToModal.id && isOpen && (
            <Button
              color="#27AE60"
              title="CONTINUAR INSCRIÇÃO"
              onClick={() =>
                loadMySubscription(dataToModal.id, dataToModal.step)
              }
              style={{
                width: "33%",
                marginTop: "42px",
              }}
            />
          )}
        </Modal>
      ) : null}
    </C.Container>
  );
};
