import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: 100vh;
    margin-bottom: 40px;
`;

export const ContentLeft = styled.div`
    width: 25%;
    padding: 20px;
    height: 100%;
    background-color: #F0F0F0;
    border-radius: 6px;
`;

export const BoxInputDate = styled.div`
    margin:20px 0;
    color: #444;

    input[type="date"] {
        width: 100%;
        margin-top: 10px;
        color: inherit;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #CCC;
        background-color: #F0F0F0;
        outline: 0;
    }
`;

export const ContentRight = styled.div`
    width: 100%;
    max-width: 1000px;
    padding: 20px 40px;

    @media screen and (max-width:760px){
        padding: 20px 0;
    }
`;

export const HeaderContentRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
        color: #777;
        font-size:12px;
    }

    @media screen and (max-width: 760px) {
        flex-direction: column;
        margin-bottom:10px;
    }
`;

export const ContentRightForm = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
`;

export const BoxFields = styled.div`
    display: flex;
    flex-direction: row;

    @media screen and (max-width:760px) {
        flex-direction: column;
    }
`;

export const ContentRightFormBoxLeft = styled.div`
    flex: 1;
    padding: 0 20px 0 0;

    @media screen and (max-width: 760px){
        padding: 0;
    }
`;
export const ContentRightFormBoxRight = styled.div`
    position: relative;
    flex: 1;

    @media screen and (max-width:760px) {
        padding: 0;
    }
`;

export const BoxButtonSubmit = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;

    button {
        width: 193px;
        @media screen and (max-width:760){
            width: 100%;
        }
    }

    @media screen and (max-width:760){
        flex-direction: column;
        margin-bottom: 40px;
    }
`;

export const GroupInputs = styled.div`
    display: flex;
    justify-content: space-between;
    width:100%;
    margin:0 0 15px 0;

    @media screen and (max-width: 760px) {
        flex-direction: column;
    }
`;

export const BoxInput = styled.div`
    width: 100%;
    border-right: 10px solid #fff;
`;

export const Search = styled.div`
    position: absolute;
    top:15px;
    right: 10px;
    color: green;
    font-size: 1.5rem;
    z-index: 5000;
    cursor: pointer;
    transition: all .4s ease;

    &:hover {
        transform: scale(1.2);
    }
`;

export const Load = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    color: green;

    span {
        position: absolute;
        top: 2px;
        width:15px;
        height:15px;
        border-top:2px solid transparent;
        border-left:2px solid green;
        border-right:2px solid green;
        border-bottom:2px solid green;
        border-radius:50%;
        animation: spin .7s infinite linear;
    }
    @keyframes spin {
        to { transform:rotate(360deg)
    }
}
`;