import { useCallback, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button/Button';
import { Input } from '../../components/input/Input';
import api from '../../services/api';
import * as C from './styles';

export const ForgotPass = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleLogin = useCallback(async() => {
        if(email === ""){
            toast.error("Preencha todos os campos!")
        }
        
        api.post("/users/forgot",{
            email
        }).then(res => {
            if(res.status === 201){
                toast.success("Verifique seu email!")
                navigate("/altera-senha")
            }
        }).catch(e => toast.error(`${e.response.data.message}`))

    },[email, navigate]);

    return (
        <>
            {/* <Header /> */}
            <C.Container>
                <h2>Desafio nacional de máxima <br /> produtividade de milho</h2>
                <C.FormLogin>
                    <C.ContentFormWrapper>
                    <h3>Recuperar senha!</h3>
                        <Input 
                            label='E-mail cadastrado' 
                            plcholder='nome@dominio.com.br'
                            onChange={(e)=>setEmail(e.target.value)} 
                            value={email}
                        />
                        
                        

                        <Button onClick={handleLogin} title='SOLICITAR' color='#27AE60' />

                        <Button onClick={() => navigate("/cadastro")} title='NÃO POSSUI CONTA? CRIE UMA AGORA!' color='#2F80ED' />
                    </C.ContentFormWrapper>
                </C.FormLogin>
            </C.Container>
        </>
    );
}